import { FirestoreBaseEntity } from "../services/firestore-repository-base.service";
import { JobStep } from "./job-step.class";

export class LvaCreationJob extends FirestoreBaseEntity {
  public currentStep = new JobStep();
  public stepsDone: number[] = [];
  public ciamId: string | null = null;
  public sesameId: number | null = null;
  public firstNames: string | null = null;
  public lastName: string | null = null;
  public birthdate: Date = new Date();
  public startDate: Date = new Date();
  public endDate: Date = new Date();
  public duration: number | null = null;
  public entriesNumber: number = 0;
  public restrictionPeriod: number | null = null;
  public contact: boolean = false;
  public voiceMail: boolean = false;
  public phone: string = '';
  public urlSignature = '';
  public signatureBase64 = '';
  public email = '';
}
