export enum Role {
  NONE = 'NONE',
  VDI = 'VDI',
  MCD = 'MCD',
  DIR_JEUX = 'DIR_JEUX',
  DIR_CASINO = 'DIR_CASINO',
  MARKETING = 'MARKETING',
  ADMIN = 'ADMIN',
  CVH = 'CVH',
  RCPT = 'RCPT'
}
