import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, Observable, of, tap } from 'rxjs';
import { IHotelList } from './dtos/hotel-list-dto';

@Injectable({
  providedIn: 'root'
})
export class HotelListContentManagerService {
  private hotelList: IHotelList[] = [];

  constructor(private http: HttpClient) {}

  getHotelList(): Observable<IHotelList[]> {
    return this.http.get<IHotelList[]>(`${environment.hotelUnifiedDataApiUrl}/Hotels`).pipe(
      tap((hotels: IHotelList[]) => this.hotelList = hotels)
    );
  }

  getHotelByCode(hotelCode: string): Observable<IHotelList | null> {
    if (this.hotelList.length === 0) {
      return this.getHotelList().pipe(
        map((hotels: IHotelList[]) => {
          const hotel = hotels.find(hotel => hotel.code === hotelCode);
          return hotel ? hotel : null;
        })
      );
    } else {
      const hotel = this.hotelList.find(hotel => hotel.code === hotelCode);
      return hotel ? of(hotel) : of(null);
    }
  }

  getHotelName(hotelCode: string): Observable<string> {
    return this.getHotelByCode(hotelCode).pipe(
      map(hotel => hotel ? hotel.name : '')
    );
  }

}
