import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, collection, doc } from "firebase/firestore";
import { CasinoService } from './casino.service';
import { AuthenticationService } from './authentication.service';
import { FirestoreRepositoryBase } from './firestore-repository-base.service';
import { UtilsService } from './utils.service';
import { IStep } from '../interfaces/step.interface';
import { StepState } from '../enums/step-state.enum';
import { FireBaseStorageService } from './firebase-storage.service';
import { ISignatureMobileJob, MobileJob } from '../models/mobile-job.class';
/**
 * Client draft service to manage the client drafts in the firestore database
 */
@Injectable({
  providedIn: 'root'
})
export class MobileJobService extends FirestoreRepositoryBase<MobileJob> {

  constructor(firestoreService: FirestoreService, private firebaseStorageService: FireBaseStorageService, casinoService: CasinoService, authenticationService: AuthenticationService, private utilsService: UtilsService) {
    super(MobileJob, casinoService.getCurrentCasino().pipe(map(casino => {
      return collection(doc(collection(firestoreService.db, 'casinos'), <string>casino?.code), 'mobileJobs').withConverter(this);
    })), authenticationService.user);
  }

  // public override toFirestore(doc: ClientDraft) {
  //   let ret = super.toFirestore(doc);

  //   if (doc?.identity?.dateOfBirth) { ret.identity.dateOfBirth = doc.identity.dateOfBirth?.toISOString(); }
  //   if (doc?.identity?.deliveryDate) { ret.identity.deliveryDate = doc.identity.deliveryDate?.toISOString(); }
  //   if (doc?.identity?.validityEndDate) { ret.identity.validityEndDate = doc.identity.validityEndDate?.toISOString(); }
  //   if (doc?.identity?.validityStartDate) { ret.identity.validityStartDate = doc.identity.validityStartDate?.toISOString(); }

  //   return ret;
  // }

  // public override fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): ClientDraft {
  //   let object = super.fromFirestore(snapshot, options)
  //   if (object?.identity?.dateOfBirth) { object.identity.dateOfBirth = new Date(object.identity.dateOfBirth); }
  //   if (object?.identity?.deliveryDate) { object.identity.deliveryDate = new Date(object.identity.deliveryDate); }
  //   if (object?.identity?.validityEndDate) { object.identity.validityEndDate = new Date(object.identity.validityEndDate); }
  //   if (object?.identity?.validityStartDate) { object.identity.validityStartDate = new Date(object.identity.validityStartDate); }
  //   return object;
  // }


  // isDate(value: any): value is Date {
  //   return value instanceof Date && !isNaN(value.getTime());
  // }

  // /**
  //    * Partial update of a document
  //    * @param id
  //    * @param data
  //    */
  // override async partialUpdate(id: string, doc: any) {

  //   if (doc?.identity?.dateOfBirth) { doc.identity.dateOfBirth = this.utilsService.toyyyymmdd(doc.identity.dateOfBirth) }
  //   if (doc?.identity?.deliveryDate) { doc.identity.deliveryDate = this.utilsService.toyyyymmdd(doc.identity.deliveryDate) }
  //   if (doc?.identity?.validityEndDate) { doc.identity.validityEndDate = this.utilsService.toyyyymmdd(doc.identity.validityEndDate) }
  //   if (doc?.identity?.validityStartDate) { doc.identity.validityStartDate = this.utilsService.toyyyymmdd(doc.identity.validityStartDate) }
  //   await super.partialUpdate(id, doc);
  // }

  /**
   * Removes a signature mobile job, witht the associated file if needed
   * @param mobileJob
   */
  async deleteSignatureMobileJob(job: ISignatureMobileJob | null) {

    if (job == null) {
      return;
    }

    await this.deleteSignatureMobileJobFile(job);
    await this.deleteAsync( job.id);
  }

  /**
   * Removes all the files in firestorage linked to the mobile job
   * @param mobileJob
   */
  async deleteSignatureMobileJobFile(job: ISignatureMobileJob | null) {

    if( !job){
      return;
    }

    if (job.data.signatureUrl) {
      await this.firebaseStorageService.deleteFileAsync(job.data.signatureUrl);
      console.log('Suppression Signature')
    }
  }

  /**
   * Delete async
   * @param id
   */
  override async deleteAsync(id: string): Promise<void> {
    let mobileJob = await this.getOneSnapshot(id);
    if (mobileJob != null) {
      await super.deleteAsync(id);
    }
  }
}
