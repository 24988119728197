import { FirestoreBaseEntity } from "../services/firestore-repository-base.service";
import { JobStep } from "./job-step.class";

export class SignatureJob extends FirestoreBaseEntity {
  currentStep = new JobStep();
  signatureBase64?: string | null;
  ciamId: string = '';
  urlSignature?: string;
  stepsDone: [] = [];
}
