<div class="version-container">

  <ng-container *ngIf="version ; else loading">
    <h2>{{title}}</h2>
    <div>
      <span>version</span>
      <span>{{ version }}</span>
    </div>
    <div *ngIf="commitHash">
      <span>commitHash</span>
      <span>{{ commitHash }}</span>
    </div>
    <div *ngIf="packageGenerationDate">
      <span>packageGenerationDate</span>
      <span>{{ packageGenerationDate }}</span>
    </div>
  </ng-container>

  <ng-template #loading>
    <mat-spinner [diameter]="25" color="primary"></mat-spinner>
  </ng-template>
</div>