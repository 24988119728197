import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { url } from 'inspector';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {


  getPathFromUrl(url: string): string {
    try {
      // Check if the URL starts with '/', if not, add it
      if (url.startsWith('/')) {

        // Add a dummy base URL to ensure the URL can be parsed
        const dummyBaseUrl = 'http://dummy.com';
        url = dummyBaseUrl + url;
      }

      const fullUrl = new URL(url);

      // Extract and return the pathname and search (query params)
      return fullUrl.pathname;
    } catch (e) {
      // If there's an error parsing the URL, return the original URL
      console.error('Error parsing URL:', e);
      return url;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (Sentry.isInitialized() === false) return next.handle(req);

    // Create a new URL object
    let path = this.getPathFromUrl(req.url);

    return Sentry.startSpanManual({ name: `${req.method} ${path}`, op: 'http.client', onlyIfParent: false }, (span) => {

      // Debug logging
      /*
      let currentScope = Sentry.getCurrentScope();
      let rootSpan = Sentry.getRootSpan(span);

      console.log(`Call: ${req.url}, root span id: ${JSON.stringify(rootSpan)}`);
      console.log(`Current trace id: ${currentTraceId}`);
      */

      // Clone the request and add the sentry-trace header if traceId is present
      let modifiedReq = req;
      if (span && !req.url.startsWith('http://127.0.0.1:5000')) {
        let baggageHeader = Sentry.spanToBaggageHeader(span);
        let traceHeader = Sentry.spanToTraceHeader(span);

        modifiedReq = req.clone({
          setHeaders: {
            "Sentry-Trace": traceHeader,
            "Baggage": baggageHeader ?? ""
          }
        });
      }

      return next.handle(modifiedReq).pipe(
        tap((res: HttpEvent<any>) => {


          if (res.type === HttpEventType.Response) {

            const parsedURL = new URL(req.url, location.origin);
            span.setAttribute("http.request.method", req.method);
            span.setAttribute("http.request.full_url", req.url);
            span.setAttribute("server.address", parsedURL.hostname);
            span.setAttribute("server.port", parsedURL.port || undefined);
            span.setAttribute("http.response.status_code", res.status);
            span.setAttribute(
              "http.response_content_length",
              Number(res.headers.get("content-length")),
            );

            // A good place to set other span attributes
            span.end();

          }
        })
      );
    });
  }
}
