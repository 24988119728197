import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AdvancedSearchResult, IClientService } from "../../../../services/czam-api/client.service";
import { FidelityLevel } from "../../../../enums/fidelity-level.enum";
import { UtilsService } from "../../../../services/utils.service";
import { OrderingDirection, SearchEngineFields } from "../../../../services/czam-api/enums/search-engine-fields.enum";
import { IRecordExist } from "../../../../services/czam-api/interfaces/record-exist";
import { IClientDto } from "../../../../dtos/client-dto.interface";

let today = new Date();
let birthDate = new Date();
birthDate.setFullYear(1980);

@Injectable({
  providedIn: 'root'
})
export class FakeClientServiceService implements IClientService {


  private clientList: any = {
    "AYGAX6s40F_0Y1Nxhh-z": {
      "id": 1,
      language: "french",
      luckyDayOne: null,
      luckyDayTwo: null,
      "ciamId": "AYGAX6s40F_0Y1Nxhh-z",
      "preferredFirstName": "Taoufik",
      "preferredLastName": "Hichem",
      "gender": "Male",
      "dateOfBirth": birthDate,
      "affiliationCasinoCode": "TEST",
      "email": "",
      "emailVerfied": false,
      "fidelityLevel": FidelityLevel.Black,
      "cardLevel": FidelityLevel.Gold,
      "validityEndDate": new Date("2023-07-09"),
      "lva": {
        "remainingEntriesInCurrentMonth": 5,
        "entriesPerMonth": 18
      },
      "phoneNumber": null,
      "addressLine": null,
      "addressCity": null,
      "postalCode": null,
      "addressCountry": null,
      "identity": {
        "identityDocumentNumber": null,
        "deliveryDate": null,
        "validityStartDate": null,
        "validityEndDate": null,
        "dateOfBirth": "1980-09-28",
        "placeOfBirth": "Hambourg",
        "countryOfBirth": "Germany",
        "birthName": "Hichem",
        "firstNames": "Taoufik",
        "identityDocumentType": null
      },
    },
    "2": {
      "id": 1,
      "ciamId": "2",
      "preferredFirstName": "Taoufik",
      "preferredLastName": "Hichem",
      "gender": "Male",
      "dateOfBirth": birthDate,
      "affiliationCasinoCode": "TEST",
      "email": "",
      "emailVerfied": false,
      "fidelityLevel": FidelityLevel.Black,
      "cardLevel": FidelityLevel.Gold,
      "validityEndDate": (new Date()).setDate(new Date().getDate() + 10),
      "phoneNumber": "0666216566",
      "addressLine": null,
      "addressCity": null,
      "postalCode": null,
      "addressCountry": null,
      "identity": {
        "identityDocumentNumber": null,
        "deliveryDate": null,
        "validityStartDate": null,
        "validityEndDate": null,
        "dateOfBirth": "1980-09-28",
        "placeOfBirth": "Hambourg",
        "countryOfBirth": "Germany",
        "birthName": "Hichem",
        "firstNames": "Taoufik",
        "identityDocumentType": null
      },
    },
    "3": {
      "id": 3,
      "ciamId": "3",
      "preferredFirstName": "Hicham",
      "preferredLastName": "Taouféliek",
      "gender": "Male",
      "dateOfBirth": birthDate,
      "affiliationCasinoCode": "TEST",
      "email": "htaoufik@gmail.com",
      "emailVerfied": false,
      "fidelityLevel": FidelityLevel.Ultimate,
      "cardLevel": FidelityLevel.Ultimate,
      "validityEndDate": (new Date()).setDate(new Date().getDate() - 10),
      "phoneNumber": null,
      "addressLine": null,
      "addressCity": null,
      "postalCode": null,
      "addressCountry": null,
      "imageUrl": "assets/lib-common/images/clients/client-demo10.jpg",
      "luckyDay": today,
      "identity": {
        "identityDocumentNumber": null,
        "deliveryDate": null,
        "validityStartDate": null,
        "validityEndDate": null,
        "dateOfBirth": "1980-09-28",
        "placeOfBirth": "Hambourg",
        "countryOfBirth": "Germany",
        "birthName": "Taouféliek",
        "firstNames": "Hicham",
        "identityDocumentType": null
      },
    },
    "4": {
      "id": 4,
      "ciamId": "4",
      "preferredFirstName": "Hicham",
      "preferredLastName": "Taouféliek",
      "gender": "Male",
      "dateOfBirth": birthDate,
      "affiliationCasinoCode": "TEST",
      "email": "htaoufik@gmail.com",
      "emailVerfied": false,
      "fidelityLevel": FidelityLevel.Gold,
      "cardLevel": FidelityLevel.Gold,
      "validityEndDate": (new Date()).setDate(new Date().getDate() - 10),
      "phoneNumber": null,
      "addressLine": null,
      "addressCity": null,
      "postalCode": null,
      "addressCountry": null,
      "imageUrl": "assets/lib-common/images/clients/client-demo10.jpg",
      "luckyDay": today,
      "lva": {
        "remainingEntriesInCurrentMonth": 0,
        "entriesPerMonth": 18
      },
      "identity": {
        "identityDocumentNumber": null,
        "deliveryDate": null,
        "validityStartDate": null,
        "validityEndDate": null,
        "dateOfBirth": "1980-09-28",
        "placeOfBirth": "Hambourg",
        "countryOfBirth": "Germany",
        "birthName": "Solvar de la plaine verdoyante",
        "firstNames": "Dimitri",
        "identityDocumentType": null
      },
    }
  }


  constructor(private utilsService: UtilsService) { }
  search(paginationToken: string | null, limit: number, fields: SearchEngineFields[] | null, sort: SearchEngineFields | null, ordering: OrderingDirection | null, filter: Object | null): Observable<AdvancedSearchResult> {
    throw new Error('Method not implemented.');
  }
  checkRecordExist(field: string, value: string): Observable<IRecordExist | null> {
    throw new Error('Method not implemented.');
  }

  getClientPictureUrl(ciamId: string): string {
    let url = this.clientList[ciamId]?.imageUrl ?? 'assets/lib-common/images/clients/client-demo18.jpg';
    return url;
  }

  getClientPictureAsBlob(ciamId: string): Observable<Blob | null> {

    let url = this.clientList[ciamId]?.imageUrl ?? 'assets/lib-common/images/clients/client-demo18.jpg';
    return this.utilsService.getImageAsBlob(url);
  }
  getClient(ciamId: string): Observable<any> {
    return of(this.clientList[ciamId]);
  }
  getClientByCard(rfid: string): Observable<IClientDto | null> {
    throw new Error('Method not implemented.');
  }
  putClient(clientId: number, client: IClientDto): {} {
    this.clientList[client.ciamId] = client;
    return client;
  }
  uploadPicture(clientId: number, picture: File): {} {

    const clients = Object.keys(this.clientList).map(key => this.clientList[key]);
    let client = clients.find((c: IClientDto) => c.id == clientId)
    if (client) {
      client.imageUrl = URL.createObjectURL(picture);
    }

    return of({});
  }
}
