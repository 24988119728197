import { ClientDraft } from '../models/client-draft.class';
import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { map } from 'rxjs';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, collection, doc } from "firebase/firestore";
import { CasinoService } from './casino.service';
import { AuthenticationService } from './authentication.service';
import { FirestoreRepositoryBase } from './firestore-repository-base.service';
import { UtilsService } from './utils.service';
import { IStep } from '../interfaces/step.interface';
import { StepState } from '../enums/step-state.enum';
import { FireBaseStorageService } from './firebase-storage.service';
/**
 * Client draft service to manage the client drafts in the firestore database
 */
@Injectable({
  providedIn: 'root'
})
export class ClientDraftService extends FirestoreRepositoryBase<ClientDraft> {

  private steps: IStep[] = [
    { title: 'Vérification restrictions', state: StepState.Closed },
    { title: 'La pièce d\'identité', state: StepState.Open },
    { title: 'Confirmation d\'informations', state: StepState.Open },
    { title: 'Signature de l\'adhésion', state: StepState.Open },
    { title: 'La photo de profil', state: StepState.Open },
    { title: 'Récapitulatif et impression', state: StepState.Open }
  ]

  constructor(firestoreService: FirestoreService, private firebaseStorageService: FireBaseStorageService, casinoService: CasinoService, authenticationService: AuthenticationService, private utilsService: UtilsService) {
    super(ClientDraft, casinoService.getCurrentCasino().pipe(map(casino => {
      return collection(doc(collection(firestoreService.db, 'casinos'), <string>casino?.code), 'clientDrafts').withConverter(this);
    })), authenticationService.user);
  }

  public getClientCreationSteps(): IStep[] {
    return this.steps;
  }

  public override toFirestore(doc: ClientDraft) {
    let ret = super.toFirestore(doc);

    if (doc?.identity?.dateOfBirth) { ret.identity.dateOfBirth = doc.identity.dateOfBirth?.toISOString(); }
    if (doc?.identity?.deliveryDate) { ret.identity.deliveryDate = doc.identity.deliveryDate?.toISOString(); }
    if (doc?.identity?.validityEndDate) { ret.identity.validityEndDate = doc.identity.validityEndDate?.toISOString(); }
    if (doc?.identity?.validityStartDate) { ret.identity.validityStartDate = doc.identity.validityStartDate?.toISOString(); }

    return ret;
  }

  public override fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): ClientDraft {
    let object = super.fromFirestore(snapshot, options)
    if (object?.identity?.dateOfBirth) { object.identity.dateOfBirth = new Date(object.identity.dateOfBirth); }
    if (object?.identity?.deliveryDate) { object.identity.deliveryDate = new Date(object.identity.deliveryDate); }
    if (object?.identity?.validityEndDate) { object.identity.validityEndDate = new Date(object.identity.validityEndDate); }
    if (object?.identity?.validityStartDate) { object.identity.validityStartDate = new Date(object.identity.validityStartDate); }
    return object;
  }


  isDate(value: any): value is Date {
    return value instanceof Date && !isNaN(value.getTime());
  }

  /**
     * Partial update of a document
     * @param id
     * @param data
     */
  override async partialUpdate(id: string, doc: any) {

    if (doc?.identity?.dateOfBirth) { doc.identity.dateOfBirth = this.utilsService.toyyyymmdd(doc.identity.dateOfBirth) }
    if (doc?.identity?.deliveryDate) { doc.identity.deliveryDate = this.utilsService.toyyyymmdd(doc.identity.deliveryDate) }
    if (doc?.identity?.validityEndDate) { doc.identity.validityEndDate = this.utilsService.toyyyymmdd(doc.identity.validityEndDate) }
    if (doc?.identity?.validityStartDate) { doc.identity.validityStartDate = this.utilsService.toyyyymmdd(doc.identity.validityStartDate) }

    await super.partialUpdate(id, doc);

  }

  /**
   * Removes all the files in firestorage linked to a client draft
   * @param clientDraft
   */
  async deleteClientDraftFiles(clientDraft: ClientDraft) {
    if (clientDraft.urlSignature) {
      await this.firebaseStorageService.deleteFileAsync(clientDraft.urlSignature);
      console.log('Suppression Signature')
    }
    if (clientDraft.urlSelfie) {
      await this.firebaseStorageService.deleteFileAsync(clientDraft.urlSelfie);
      console.log('Selfie Supprimé')
    }
    if (clientDraft.identityDocumentPicture.urlRecto) {
      await this.firebaseStorageService.deleteFileAsync(clientDraft.identityDocumentPicture.urlRecto);
      console.log('Recto ID Scan Supprimé')
    }
    if (clientDraft.identityDocumentPicture.urlVerso) {
      await this.firebaseStorageService.deleteFileAsync(clientDraft.identityDocumentPicture.urlVerso);
      console.log('Verso ID Scan Supprimé')
    }
  }

  /**
   * Delete async
   * @param id
   */
  override async deleteAsync(id: string): Promise<void> {
    let clientDraft = await this.getOneSnapshot(id);
    if (clientDraft != null) {
      await this.deleteClientDraftFiles(clientDraft);
      await super.deleteAsync(id);
    }
  }
}
