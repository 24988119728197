import { ICasinoDto } from '../../dtos/casino-dto';
import { CasinoService } from '../../services/casino.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ISelectCasinoDialogData {
  casinoList: ICasinoDto[];
  currentCasino: ICasinoDto | null;
}

@Component({
  selector: 'app-select-casino-dialog',
  templateUrl: './select-casino-dialog.component.html',
  styleUrls: ['./select-casino-dialog.component.scss']
})
export class SelectCasinoDialogComponent implements OnInit {

  public casinos: any
  public value: ICasinoDto | null = null;

  constructor(
    public modalDialogRef: MatDialogRef<SelectCasinoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectCasinoDialogData,
    public casinoService: CasinoService,
  ) {
  }

  async ngOnInit() {
    this.casinos = this.data.casinoList;
    this.value = this.data.currentCasino ? this.data.currentCasino : this.data.casinoList.length > 0 ? this.data.casinoList[0] : null;
  }

  async saveCasino() {
    if (this.value!.code) {
      this.modalDialogRef.close({
        currentCasino: this.value,
        casinoList: this.casinos
      });
    }
  }
}
