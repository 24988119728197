import { Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { Firestore, connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  public db!: Firestore;

  constructor() { }

  async init(app: FirebaseApp, env: string) {
    // Initialize Cloud Firestore and get a reference to the service

    this.db = initializeFirestore(app, {
      experimentalAutoDetectLongPolling: true
    });

    // if (env == 'Local') {
    //   // change to emulator if needed
    //   connectFirestoreEmulator(this.db, 'localhost', 8060);
    // }
  }
}
