import { Directive, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription, combineLatest, map } from 'rxjs';
import { Role } from '../enums/role.enum';
import { AuthenticationService } from '../services/authentication.service';
import { CasinoService } from '../services/casino.service';
import { RoleGuardCheckService } from "../services/role-guard-check.service";

export interface IRightsContainer {
  necessaryRoles: Role[];
}

@Directive({
  selector: '[isGranted]'
})
export class IsGrantedDirective implements OnInit, OnDestroy {

  private _roles: Role[] = [];

  @Input()
  set isGranted(roles: string) {
    this._roles = roles.split('|') as Role[];
  }

  private isVisible: boolean = false;
  private subscription?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService,
    @Optional() private casinoService: CasinoService,
    private roleGuardCheckService: RoleGuardCheckService
  ) { }

  ngOnInit() {
    this.subscription = this.roleGuardCheckService.getRoleGuard(this._roles).subscribe((isRoleActive: boolean) => {
      if (isRoleActive) {

        // We much check if the component has not already been created, to avoid creating it multiple times
        if (!this.isVisible) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.isVisible = true;
        }
      } else {
        this.viewContainer.clear();
        this.isVisible = false;
      }
    });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription?.unsubscribe();
  }
}
