import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public static SETTINGS = 'settings';

  constructor() { }

  public static set(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }

  public static get(key: string) {
    let val = localStorage.getItem(key) || '';

    if (LocalStorageService.isStringJson(val)) {

      try {
        let ret = JSON.parse(val);
        return ret;
      } catch (error) {
        console.log("Error, could not parse", error, val);
        return {};
      }

    }
    return val;
  }

  public static delete(key: string) {
    localStorage.removeItem(key);
  }

  static isStringJson(text: string) {

    if (!text) {
      return false;
    }

    if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
      replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
      replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

      return true

    }
    else {
      return false
    }
  }
}
