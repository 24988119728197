import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { timeout, catchError } from "rxjs/operators";
import { DowngradedModeService } from '../services/czam-api/downgraded-mode.service';

// default request timeout (injected) to wait before throwing an error from a HttpRequest
// Note: default request timeout value is set in `app.module.ts`
export const REQUEST_DEFAULT_TIMEOUT = new InjectionToken<number>('requestTimeout');

@Injectable({
    providedIn: 'root',
})
export class TimeoutRequestInterceptorService implements HttpInterceptor {

    // http status codes reveied from backend that should trigger downgraded-mode
    //   0: Unknown (we consider it's a network failure)
    // 502: Bad Gateway / Proxy Error
    // 503: Service Unavailable
    // 504: Gateway Time-out
    private backendHttpRequestCodes : number[] = [ 0, 502, 503, 504 ];

    public constructor(@Inject(REQUEST_DEFAULT_TIMEOUT) protected requestTimeout: number, private downgradedModeService: DowngradedModeService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(request.url.includes(':5000')){
            return next.handle(request);
        }
        // request with timeout (in milliseconds)
        const requestWithTimeout = request.clone({
            setHeaders: { 'X-Request-Timeout': `${this.requestTimeout}` }
        });

        return next
            .handle(requestWithTimeout)
            .pipe(
                // throw a TimeoutError if the source didn't push a value within the allowed timeout
                timeout(this.requestTimeout),
                // on error, set CZAM to downgraded-mode for TimeoutError and Server-side errors
                catchError(error => {
                    if (error instanceof TimeoutError) {
                        // timeout error
                        console.log(`Client-side HTTP timeout from: ${requestWithTimeout.url}, got error: ${error.message} (${error.info})`);

                        this.downgradedModeService.setDowngradedMode(true);
                    }
                    else if (error instanceof HttpErrorResponse) {
                        // client-side error
                        if (error.error instanceof ErrorEvent) {
                            console.log(`Client-side HTTP error ${error.status} from: ${requestWithTimeout.url}, got error: ${error.error.message} (${error.statusText})`);
                        // server-side error
                        } else {
                            console.log(`Server-side HTTP error ${error.status} from: ${requestWithTimeout.url}, got error: ${error.message}`);

                            // only switch to degraded-mode on specific http errors
                            if (this.backendHttpRequestCodes.includes(error.status))
                            {
                                this.downgradedModeService.setDowngradedMode(true);
                            }
                        }
                    }

                    return throwError(() => error);
                })
            );
    }
}
