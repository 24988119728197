import { FirestoreBaseEntity } from '../services/firestore-repository-base.service';
import { Gender } from '../enums/gender.enum';
import { JobStep } from './job-step.class';
import { ActionType } from '../services/czam-api/enums/action-type.enum';


export interface ISignatureData{
    signatureUrl: string;
}

export interface ISignatureMobileJob{
    id: string;
    currentStep: JobStep | null;
    type: string;
    data: ISignatureData;
}

export class MobileJob extends FirestoreBaseEntity {
    currentStep: JobStep | null = null;
    type: ActionType = ActionType.Signature;
    data: any;

    /**   
     * * Assign all the values from the given parameter to the object properties when available   *
     * @param value   */
    assign(value: any): MobileJob {
        return Object.assign(this, value);
    }

}
