import { Injectable } from '@angular/core';
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environmentDevelopment } from "../environments/environment.development";
import { environmentProduction } from "../environments/environment.production";
import { environmentStaging } from "../environments/environment.staging";
import { environmentLocal } from "../environments/environment.local";

declare global {
  interface Window {
    env?: string;
  }
}

interface ConfigSystem {
  stage: string;
}

interface Configuration {
  stage: string;
  googleTagManagerContainerId: string;
  googleTagManagerAuth: string;
  googleTagManagerPreview: string;
  googleTagManagerMode: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {


  public constructor(private http: HttpClient) {
  }


  async getEnvironmentConfiguration(): Promise<Configuration> {
    const env = await firstValueFrom(this.http.get<ConfigSystem>('/system'));
    const stage = env.stage;

    window.env = stage;

    switch (stage) {
      case 'dev':
        return { ...environmentDevelopment, stage };
      case 'prod':
        return { ...environmentProduction, stage };
      case 'uat':
        return { ...environmentStaging, stage };
      default:
        return { ...environmentLocal, stage };
    }
  }
}
