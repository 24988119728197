import { FirestoreRepositoryBase } from "./firestore-repository-base.service";
import { SignatureJob } from "../models/signature-job.class";
import { FirestoreService } from "./firestore.service";
import { CasinoService } from "./casino.service";
import { AuthenticationService } from "./authentication.service";
import { map } from "rxjs";
import { collection, doc } from "firebase/firestore";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SignatureJobService extends FirestoreRepositoryBase<SignatureJob> {

  constructor(
    firestoreService: FirestoreService,
    casinoService: CasinoService,
    authenticationService: AuthenticationService
  ) {
    super(SignatureJob, casinoService.getCurrentCasino().pipe(map(casino => {
      return collection(doc(collection(firestoreService.db, 'casinos'), <string>casino?.code), 'signatureJobs').withConverter(this);
    })), authenticationService.user);
  }
}
