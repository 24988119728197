export enum ActionType {
  Signature = 'Signature',
  Photo = 'Photo',
  IdentityDocument = 'IdentityDocument',
  Message = 'Message',
  card = 'Card'
}

export enum ActionState {
  Active = 'Active',
  Inactive = 'Inactive',
  Done = 'Done'
}