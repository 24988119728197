import { HttpClient } from "@angular/common/http";
import { Injectable, Optional } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { SwPush } from '@angular/service-worker';
import { AuthenticationService } from "../authentication.service";
import { CasinoService } from '../casino.service';

/**
 * This sevice provides utilities to automatically subscribe and unsubscribe from push notifications.
 */
@Injectable({ providedIn: 'root' })
export class WebPushNotificationsService {
  constructor(private auth: AuthenticationService, private http: HttpClient, private swPush: SwPush, @Optional() private casinoService: CasinoService) {
    this.retrievePushPublicKey()
  }

  config?: NotificationsApiSystemInformation;
  subscribing = false;
  token?: string;
  sub?: WebPushSubscriptionId;

  async retrievePushPublicKey() {
    this.config = await firstValueFrom(this.http.get<NotificationsApiSystemInformation>('/notifications-api/system'))
  }

  public init() {

    this.auth.isLoggedIn.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.subscribeToPush();
      } else {
        this.unsuscribeFromPush();
      }
    });
  }

  async subscribeToPush() {
    if (this.subscribing) {
      //happens because auth sevice notifies twice on startup (once for auth, once for token)
      console.log("WebPushNotificationsService.subscribeToPush", "already subscribing")
      return;
    }
    if (!this.config) {
      //strange : the call to retrieve the config made in the ctor did not succeed or is not finished yet
      console.log("WebPushNotificationsService.subscribeToPush", "no config")
      return;
    }
    if (!this.swPush.isEnabled) {
      //in order to enable push, you need to use a production build (service worker is not working in dev)
      //=> npm run build --configuration=production && lite-server --baseDir=dist
      //you might need to install lite-server : npm i -g lite-server
      //you can then test from http://localhost:3000/project-name/index.html
      //be aware that yo need to rebuild after each code modification, this is a nightmare ...
      console.log("WebPushNotificationsService.subscribeToPush", "swPush not enabled")
      return;
    }
    console.log("WebPushNotificationsService.subscribeToPush", "trying...")
    this.subscribing = true
    try {
      var sub = await this.swPush.requestSubscription({
        serverPublicKey: this.config.webPushPublicKey
      });
    } catch (e: any) {
      console.log("WebPushNotificationsService.subscribeToPush", "failed", e)
      return
    }
    let subJson = sub.toJSON()
    console.log("WebPushNotificationsService.subscribeToPush", "subscribed to push server", subJson);

    let casinoCode = null;
    if (this.casinoService) {
      casinoCode = this.casinoService.getCasinoCodeSync();
    }


    let body: WebPushSubscription = {
      endpoint: sub.endpoint,
      auth: subJson.keys!['auth'],
      p256DH: subJson.keys!['p256dh'],
      pushDeviceId: this.getPushDeviceId(),
      userPlace: casinoCode
    }
    this.sub = await firstValueFrom(this.http.post<WebPushSubscriptionId>('/notifications-api/webpush/subscriptions', body))
    console.log("WebPushNotificationsService.subscribeToPush", "subscribed to notifications-api", this.sub)
    this.subscribing = false;
  }

  async unsuscribeFromPush() {
    console.log("WebPushNotificationsService.unsuscribeFromPush")
    if (!this.sub) {
      return
    }
    await firstValueFrom(this.http.delete(`/notifications-api/webpush/subscriptions/${this.sub.id}`))
    console.log("WebPushNotificationsService.unsuscribeFromPush", "unsubscribed")
  }

  getPushDeviceId() {
    var res = localStorage.getItem("PushDeviceId");
    if (!res) {
      res = Array.from(Array(16))
        .map(e => Math.floor(Math.random() * 255).toString(16).padStart(2, "0"))
        .join('');
      localStorage.setItem("PushDeviceId", res);
    }
    return res;
  }
}

class NotificationsApiSystemInformation {
  webPushPublicKey: string = '';
}

class WebPushSubscription {
  endpoint: string = '';
  p256DH: string = '';
  auth: string = '';
  pushDeviceId: string = '';
  userPlace: string | null = null;
}

class WebPushSubscriptionId {
  id: string = '';
}