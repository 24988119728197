import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AddAuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthenticationService) { }


  async addAuthorisationAndHandle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    let token = await this.authService.getJwtToken();
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    })

    return await lastValueFrom(next.handle(authReq));
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes('/casino-loyalty-api') ||
      request.url.includes('/casino-inj-api') ||
      request.url.includes('/casino-restrictions-api') ||
      request.url.includes('/czam-api') ||
      request.url.includes('/ocm-connect-api') ||
      request.url.includes('/wallet-api') ||
      request.url.includes('/notifications-api') ||
      request.url.includes('/hotel-unifieddata-api') ||
      request.url.includes('/user-apps-api') ||
      request.url.includes('/swift-sale-api')
    ) {
      return from(this.addAuthorisationAndHandle(request, next));
    }

    return next.handle(request);
  }
}
