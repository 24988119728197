import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, map, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { IClientDto } from '../../dtos/client-dto.interface';
import { FidelityLevel } from '../../enums/fidelity-level.enum';
import { ClientState } from '../../enums/client-state.enum';
import { OrderingDirection, SearchEngineFields } from './enums/search-engine-fields.enum';
import { CasinoService } from '../casino.service';
import { ErrorService } from '../error.service';
import { ClientIdentityDocumentType } from '../../enums/client-identity-document-type.enum';
import { PreviousCardDeactivation } from '../../enums/previous-card-deactivation.enum';
import { IClientReaffectationDto } from '../../dtos/client-reaffection-dto.interface';
import { IRecordExist } from './interfaces/record-exist';
import { RenewIdentityDocumentRequest } from './dtos/renew-identity-document-request';
import { ClientDeactivationCause } from '../../enums/client-deactivation-cause.enum';
import { IConsentUpdateDto } from './dtos/consent-update-dto';
import { DocumentStatusDto } from './dtos/document-status-dto';
import { IConsentAuditTrailDto } from './dtos/consent-audit-trail-dto';
import { DocumentCnilInformation } from "../../models/document-cnil-information.class";
import { IClientDataReviewDto } from './dtos/client-data-review-dto';
import { SimpleSearchResultDto } from './dtos/simple-search-result-dto';

export interface IClientService {
  getClientPictureAsBlob(ciamId: string): Observable<Blob | null>;
  getClient(ciamId: string): Observable<any>;
  getClientByCard(rfid: string): Observable<IClientDto | null>;
  putClient(clientId: number, client: IClientDto): {};
  uploadPicture(clientId: number, picture: File): {};
  getClientPictureUrl(ciamId: string): string;
  search(paginationToken: string | null, limit: number, fields: SearchEngineFields[] | null, sort: SearchEngineFields | null, ordering: OrderingDirection | null, filter: Object | null): Observable<AdvancedSearchResult>;
  checkRecordExist(field: string, value: string): Observable<IRecordExist | null>;
}

export interface AdvancedSearchResult {
  paginationToken: string | null,
  result: any[]
}

export enum SignatureTypeEnum {
  SESAME = 'Sesame',
  CZAM = 'Czam',
  BPLAY = 'BPlay',
  NONE = 'None'
}

@Injectable({
  providedIn: 'root'
})
export class ClientService implements IClientService {

  private urlBase = environment.czamApiUrl + '/clients';
  constructor(private http: HttpClient, private errorService: ErrorService, private casinoService: CasinoService) {
  }

  getClientPictureAsBlob(ciamId: string): Observable<Blob | null> {
    return this.http.get(this.getClientPictureUrl(ciamId), { responseType: "arraybuffer" })
      .pipe(map(g => {
        return new Blob([g]);
      }), catchError(err => {
        return of(null);
      }));
  }

  getClientPictureUrl(ciamId: string): string {
    return `${this.urlBase}/${ciamId}/picture`;
  }

  hasVerso(identityDocumentType: ClientIdentityDocumentType) {
    if ([ClientIdentityDocumentType.ResidencePermit, ClientIdentityDocumentType.IdentityCard].includes(identityDocumentType!)) {
      return true;
    }
    return false;
  }

  getClientIdentityDocumentUrl(ciamId: string, page: number = 0): string {
    return `${this.urlBase}/${ciamId}/identity-document?pageNumber=${page}`
  }

  getClient(ciamId: string, forceSync = false, askFidelity: boolean = false): Observable<IClientDto> {
    return this.http.get<IClientDto>(`${this.urlBase}/${ciamId}?forceSync=${forceSync}&askFidelity=${askFidelity}`).pipe(map((data) => {
      return data;
    }));
  }

  getClientByCard(rfid: string, askFidelity: boolean = false): Observable<IClientDto | null> {
    return this.http.get<IClientDto>(`${this.urlBase}/card/${rfid}?askFidelity=${askFidelity}`).pipe(map((data) => {
      return data;
    }));
  }

  putClient(clientId: number, client: IClientDto) {
    return this.http.put<IClientDto>(`${this.urlBase}/${clientId}`, client).pipe(catchError(err => {
      this.errorService.error(err);
      throw err;
    }));
  }

  reaffectClient(clientId: number, client: IClientReaffectationDto): Observable<IClientReaffectationDto> {
    return this.http.put<IClientReaffectationDto>(`${this.urlBase}/reaffectation/${clientId}`, client).pipe(catchError(err => {
      this.errorService.error(err);
      throw err;
    }));
  }

  deleteClient(ciamId: string) {
    return this.http.delete<boolean>(`${this.urlBase}/${ciamId}`).pipe(catchError(err => {
      this.errorService.error(err);
      throw err;
      return of(null);
    }));
  }

  updateClientState(ciamId: string, state: ClientState, deactivationCause: ClientDeactivationCause | null = null) {
    return this.http.put<IClientDto>(`${this.urlBase}/casinos/${this.casinoService.getCasinoCodeSync()}/players/${ciamId}/state`, { state, deactivationCause }).pipe(catchError(err => {
      this.errorService.error(err);
      throw err;
    }))
  }

  uploadPicture(clientId: number, picture: File) {
    const formData = new FormData();
    formData.append('file', picture, picture.name);

    return this.http.post(`${this.urlBase}/${clientId}/picture`, formData);
  }

  uploadDocumentIdentity(clientId: number, files: File[]) {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++)
      formData.append('files', files[i], files[i].name);

    return this.http.post(`${this.urlBase}/${clientId}/identity-documents`, formData);
  }


  public static fidelityLevelToString(level: FidelityLevel, isFem: boolean = true): string {

    var text = "";
    switch (level) {
      case FidelityLevel.Gold:
        text = 'Or';
        break;
      case FidelityLevel.White:
        text = isFem ? 'Blanche' : 'Blanc';
        break;
      case FidelityLevel.Black:
        text = isFem ? 'Noire' : 'Noir';
        break;
      case FidelityLevel.Silver:
        text = 'Argent';
        break;
      case FidelityLevel.Ultimate:
        text = 'Ultime';
        break;
      default:
        text = 'Pas de carte';
        break;
    }

    return text;
  }

  sendVerificationEmail(ciamId: string) {
    return this.http.post(`${this.urlBase}/${ciamId}/email-verification`, null)
  }

  deactiveCard(ciamId: string, deactivationCause: PreviousCardDeactivation) {
    return this.http.put(`${this.urlBase}/casinos/${this.casinoService.getCasinoCodeSync()}/players/${ciamId}/card/state`, { "state": "Inactive", "cardDeactivationCause": deactivationCause })
  }

  activateCard(ciamId: string, magneticeCode: string, rfid: string | null, fidelityLevel: FidelityLevel) {
    return this.http.post(`${this.urlBase}/casinos/${this.casinoService.getCasinoCodeSync()}/players/${ciamId}/card`, { "magneticCode": magneticeCode, "rfid": rfid, "fidelityLevel": fidelityLevel })
  }

  search(
    paginationToken: string | null = null,
    limit: number = 50,
    fields: SearchEngineFields[] | null,
    sort: SearchEngineFields | null = null,
    ordering: OrderingDirection | null = null,
    filter: Object | null = null
  ): Observable<AdvancedSearchResult> {
    let params: string[] = [];

    if (sort) {
      let direction = '';
      if (ordering == OrderingDirection.Desc) {
        direction += '-';
      }
      params.push(`sort=${direction + sort}`);
    }

    if (paginationToken) {
      params.push(`paginationToken=${paginationToken}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }
    if (fields) {
      params.push(`fields=${fields.join(',')}`);
    }
    if (filter) {
      Object.entries(filter).forEach(pair => {
        params.push(`${pair[0]}=${pair[1].replace('+', '%2B')}`);
      });
    }
    return this.http.get<AdvancedSearchResult>(`${this.urlBase}/advanced?${params.join('&')}`);
  }


  simpleClientSearchBySesameIds(sesameIds: number[]): Observable<SimpleSearchResultDto> {
    const url = `${this.urlBase}/simple`;
    const params = new HttpParams().set('sesameIds', sesameIds.join(',')); // convert array of sesameIds to comma-separated string
    return this.http.get<SimpleSearchResultDto>(url, { params });
  }

  simpleClientSearch(firstNames?: string, lastName?: string, limit: number = 50, paginationToken: string | null = null, sesameId: number | null = null, dateOfBirth: string | null = null): Observable<SimpleSearchResultDto> {
    let httpParams = new HttpParams();

    let params: any;
    if (sesameId) {
      params = { sesameId, limit, paginationToken };
    } else {
      params = { firstNames, lastName, limit, paginationToken };
    }

    if (dateOfBirth !== null) {
      params['dateOfBirth'] = dateOfBirth
    }

    Object.keys(params).forEach(key => {
      if (params[key] != null) {
        httpParams = httpParams.append(key, params[key].toString());
      }
    });

    return this.http.get<SimpleSearchResultDto>(`${this.urlBase}/simple`, { params: httpParams })
      .pipe(
        catchError(error => {
          this.errorService.error(error);
          return of(new SimpleSearchResultDto());
        })
      );
  }

  checkRecordExist(field: string, value: string) {
    return this.http.get<IRecordExist>(`${this.urlBase}/records/${field}/exist?value=${value}`)
      .pipe(
        catchError(error => {
          this.errorService.error(error);
          return of(null);
        })
      );
  }

  renewIdentityDocument(ciamId: string, request: RenewIdentityDocumentRequest) {
    return this.http.put<IClientDto>(`${this.urlBase}/renew-identity-document/${ciamId}`, request);
  }

  updateCardValidityDate(ciamId: string, idValidityEndDate: string | null) {
    return this.http.put<IClientDto>(`${this.urlBase}/update-card-validity/${ciamId}`, { identityDocumentValidityEndDate: idValidityEndDate });
  }

  getClientsBySesameId(sesameIds: number[]): Observable<IClientDto[]> {
    const url = `${this.urlBase}/by-sesame-ids`;
    const params = new HttpParams().set('values', sesameIds.join(',')); // convert array of sesameIds to comma-separated string
    return this.http.get<IClientDto[]>(url, { params });
  }

  getOneClientBySesameId(sesameId: number): Observable<IClientDto | null> {
    return this.getClientsBySesameId([sesameId]).pipe(
      map((clients: IClientDto[]) => clients.find((client: IClientDto) => client.sesameId == sesameId) || null),
      catchError(err => {
        this.errorService.error('Erreur lors de la récupération des informations client : ' + err.error.error);
        return of(null);
      })
    );
  }

  getClientSignature(ciamId: string): string {
    return `${this.urlBase}/${ciamId}/term-of-use/signature`;
  }

  getClientCnilDocument(ciamId: string): string {
    return `${this.urlBase}/${ciamId}/cnil-document`;
  }

  getClientCnilDocuments(ciamId: string): Observable<DocumentCnilInformation[]> {
    return this.http.get<DocumentCnilInformation[]>(`${this.urlBase}/${ciamId}/cnil-documents`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la récupération de l'historique des optins du client : " + err.error.error);
        return of([]);
      })
    );
  }

  uploadSignature(ciamId: string, picture: File) {
    const formData = new FormData();
    formData.append('file', picture, picture.name);
    return this.http.post(`${this.urlBase}/${ciamId}/term-of-use/signature`, formData);
  }

  validateClient(ciamId: string) {
    return this.http.put(`${this.urlBase}/${ciamId}/validate`, {}).pipe(
      catchError(err => {
        this.errorService.error('Erreur lors de validation du client : ' + err.error.error);
        return of(null);
      })
    );
  }

  updateConsent(ciamId: string, body: IConsentUpdateDto) {
    return this.http.post(`${this.urlBase}/${ciamId}/opt-ins`, body).pipe(
      catchError(err => {
        this.errorService.error('Erreur lors de la mise à jour des optins du client : ' + err.error.error);
        return of(null);
      })
    );
  }

  getConsentAuditTrail(ciamId: string): Observable<IConsentAuditTrailDto[]> {
    return this.http.get<IConsentAuditTrailDto[]>(`${this.urlBase}/${ciamId}/consent-audit-trail?sort=-CreationDate`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la récupération de l'historique des optins du client : " + err.error.error);
        return of([]);
      })
    );
  }

  getClientIdentityDocumentStatus(ciamId: string) {
    return this.http.get<DocumentStatusDto>(`${this.urlBase}/${ciamId}/identity-document/status`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors du check de la présence de la pièce d'identité : " + err.error.error);
        return of(null);
      })
    );
  }

  getCnilDocumentsStatus(ciamId: string) {
    return this.http.get<DocumentStatusDto>(`${this.urlBase}/${ciamId}/cnil-document/status`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors du check de la fiche d'adhésion : " + err.error.error);
        return of(null);
      }));
  }

  getSignatureDocumentStatus(ciamId: string) {
    return this.http.get<DocumentStatusDto>(`${this.urlBase}/${ciamId}/signature/status`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors du check de la présence de la signature : " + err.error.error);
        return of(null);
      }));
  }

  async computeSignatureType(client: IClientDto): Promise<SignatureTypeEnum> {
    if ((await firstValueFrom(this.getSignatureDocumentStatus(client.ciamId)))?.exists) {
      return SignatureTypeEnum.CZAM;
    } else if ((await firstValueFrom(this.getCnilDocumentsStatus(client.ciamId)))?.exists) {
      return SignatureTypeEnum.SESAME;
    }
    else if (client?.isBPlay) {
      return SignatureTypeEnum.BPLAY;
    } else {
      return SignatureTypeEnum.NONE;
    }
  }

  // client data review
  createClientDataReview(clientId: number, review: string) {
    return this.http.post(`${this.urlBase}/${clientId}/client-data-reviews`, {'description': review}).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors du review du client : " + err.error.error);
        return of(null);
      })
    );
  }

  getClientDataReviews(clientId: number): Observable<IClientDataReviewDto[]> {
    return this.http.get<IClientDataReviewDto[]>(`${this.urlBase}/${clientId}/client-data-reviews`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la récupération du review du client : " + err.error.error);
        return of([]);
      })
    );
  }

  completeClientDataReview(id: number) {
    return this.http.post(`${environment.czamApiUrl}/client-data-reviews/${id}/completion`, {}).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la completion du review du client : " + err.error.error);
        return of(null);
      })
    );
  }

  getClientsByEmail(email: string): Observable<IClientDto[]> {
    return this.http.get<IClientDto[]>(`${this.urlBase}/by-email?email=${email}`).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la recherche par mail : " + err.error.error);
        return of([]);
      }));
  }

  synchronizeClient(ciamId: string, casinoCode: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.urlBase}/${ciamId}/synchronization`, {'requesterCasinoCode': casinoCode}).pipe(
      catchError(err => {
        this.errorService.error("Erreur lors de la synchronisation du client : " + err.error.error);
        return of(false);
      }));
  }
}
