import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet-component';
import { BottomSheetCloseReason } from "../../enums/process-steps.enum";


@Component({
  selector: 'app-confirmation-bottom-sheet',
  templateUrl: './confirmation-bottom-sheet.component.html',
  styleUrls: ['./confirmation-bottom-sheet.component.scss']
})
export class ConfirmationBottomSheetComponent {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
      title?: string;
      subtitle?: string;
      text: string,
      imgUrl?: string,
      yesButtonCaption: string,
      noButtonCaption: string
    }) {
  }

  confirm() {
    this._bottomSheetRef.dismiss(BottomSheetCloseReason.Yes);
  }

  cancel() {
    this._bottomSheetRef.dismiss(BottomSheetCloseReason.No);
  }
}
