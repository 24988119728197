import { IIdentityDocumentDto } from '../dtos/Identity-document-dto';
import { ClientPreferredContact } from '../enums/client-preferred-contact.enum';
import { Gender } from '../enums/gender.enum';
import { ClientBase } from '../interfaces/client-base.interface';
import { FirestoreBaseEntity } from '../services/firestore-repository-base.service';
import { JobStep } from './job-step.class';


export class ClientDraft extends FirestoreBaseEntity implements ClientBase {
  createdByUser: string | null = null;
  lastModifiedByUser: string | null = null;
  identityDocumentPicture: IdentityDocumentPicture = new IdentityDocumentPicture();
  identityDocumentPictures: string[] = [];
  currentStep = new JobStep();
  birthName: string = '';
  preferredFirstName: string = '';
  preferredLastName: string = '';
  gender: Gender | null = null;
  affiliationCasinoCode?: string = '';
  email?: string = '';
  phoneNumber?: string = '';
  ciamId?: string = '';
  cvip?: string = '';
  luckyDayOne?: string = '';
  addressLine?: string = '';
  address2?: string = '';
  deliveryNote?: string = '';
  addressCity: string = '';
  addressPostalCode: string = '';
  addressCountry: string = '';
  identity: IIdentityDocumentDto = {} as IIdentityDocumentDto;
  language: string = '';
  nationality: string = '';
  preferredContact: ClientPreferredContact = ClientPreferredContact.Unknown;
  preferredLanguage?: string = '';
  postalNotifications?: boolean = false;
  smsNotifications?: boolean = false;
  stepsDone: number[] = [];
  emailNotifications?: boolean = false;
  casinoConsent?: boolean = false;
  groupConsent?: boolean = false;
  cguConsent?: boolean = false;
  partnersConsent?: boolean = false;
  ariadDocumentId?: string = '';
  urlSelfie?: string | null = null;
  urlSignature?: string | null = null;
  signature?: string;
  sponsorSesameId: number | null = null;

  /**   
   * * Assign all the values from the given parameter to the object properties when available   *
   * @param value   */
  assign(value: any): ClientDraft {
    return Object.assign(this, value);
  }

}
export class IdentityDocumentPicture {
  urlRecto: string = '';
  urlVerso: string = '';
}
