import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IClientDto } from "../../dtos/client-dto.interface";

export class SnackbarData {
  message: string | null = null;
  client: IClientDto | null = null;
  icon: string | null = null;
  class: string | null = null;
}

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) { }

  ngOnInit(): void {
  }
}
