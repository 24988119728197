import { finalize, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import { Unsubscribe, User } from "firebase/auth";
import { addDoc, CollectionReference, deleteDoc, doc, DocumentData, DocumentReference, endAt, FieldPath, FirestoreDataConverter, getDoc, limit, onSnapshot, orderBy, OrderByDirection, query, QueryConstraint, QueryDocumentSnapshot, setDoc, SnapshotOptions, startAfter, updateDoc, where, WhereFilterOp } from 'firebase/firestore';


export class FirestoreBaseEntity {
  creationDate: Date = new Date();
  lastModificationDate: Date = new Date();
  owner: string = '';
  ownerId: string = '';
  lastModifiedByEmail: string = '';
  id!: string;
  documentReference: DocumentReference<DocumentData> | null = null;
}


export class Filter {
  fieldPath!: string | FieldPath;
  opStr!: WhereFilterOp;
  value: unknown;

  /**
   * Constructor
   */
  constructor(fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown) {
    this.fieldPath = fieldPath;
    this.opStr = opStr;
    this.value = value;
  }
}

/**
 * Class used to order the list request
 */
export class Ordering {
  field: string;
  direction: string;

  /**
   * Constructor
   */
  constructor(field: string, direction: string = "asc") {
    this.field = field;
    this.direction = direction;
  }
}

/**
 * Interface to set the options for the list call
 */
export interface IListOptions<T> {
  filters?: Filter[];
  orderBy?: Ordering[];
  startAfter?: T | null;
  endAt?: T | null;
  limit: number;
}

/**
 * Interface returned by the list call
 */
export interface IListResult<T> {
  result: T[];
  paginationToken: any;
}

export class FirestoreRepositoryBase<T extends FirestoreBaseEntity> implements FirestoreDataConverter<T> {
  private owner: string = '';
  private ownerId: string = '';

  private defaultLimitInList = 50;
  private dbPath$: Observable<CollectionReference<T>>;

  constructor(protected entityConstructor: new () => T, dbPath: Observable<CollectionReference<DocumentData>>, $user: Observable<User | null>) {

    this.dbPath$ = dbPath.pipe(map(item => item.withConverter(this)));;
    $user.subscribe(user => {
      if (user) {
        this.owner = <string>user.email;
        this.ownerId = user.uid;
      }
    });
  }

  private toAnonymousObject(obj: any): any {

    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // We must ignore these types as they create circular dependencies
    if (obj instanceof DocumentReference || obj instanceof Observable) {
      return null;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => this.toAnonymousObject(item));
    }

    const anonymousObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        anonymousObj[key] = this.toAnonymousObject(obj[key]);
      }
    }
    return anonymousObj;
  }


  /**
  * Firestore generic data converter
  */
  public toFirestore(doc: T) {
    let ret: any = this.toAnonymousObject(doc);
    ret.lastModificationDate = doc.lastModificationDate?.toISOString() ?? new Date().toISOString();
    ret.creationDate = doc.creationDate?.toISOString();
    delete ret.id;
    delete ret.documentReference;
    return ret;
  }

  public fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): T {
    const data = snapshot.data(options) as T;
    let object = Object.assign(new this.entityConstructor(), data);
    object.creationDate = object.creationDate ? new Date(object.creationDate) : new Date();
    object.lastModificationDate = object.lastModificationDate ? new Date(object.lastModificationDate) : new Date();
    object.id = snapshot.id;
    object.documentReference = snapshot.ref;
    return object;
  }


  protected getCollectionReference(): Observable<CollectionReference<T>> {
    return this.dbPath$;
  }

  protected getDocumentReference(id: string | null = null): Observable<DocumentReference<T>> {
    return this.getCollectionReference().pipe(map(col => {
      if (id) {
        return doc(col, id);
      } else {
        return doc(col);
      }
    }))
  }

  async getOneSnapshot(id: string): Promise<T | null> {
    const docRef = await firstValueFrom(this.getDocumentReference(id));
    return this.getOneSnapshotByDocumentReference(docRef);
  }

  async getOneSnapshotByDocumentReference(reference: DocumentReference<T>) {
    var docSnap = await getDoc(reference.withConverter(this));
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      return null;
    }
  }

  getOne(id: string): Observable<T | null> {
    let subject = new ReplaySubject<T | null>(1);
    let documentRef$ = this.getDocumentReference(id);
    let unsubscribe: Unsubscribe;

    let documentRefSubscription = documentRef$.subscribe({
      next: (docRef) => {
        unsubscribe = onSnapshot(docRef, (doc) => {
          if (doc.exists()) {
            subject.next(doc.data());
          } else {
            subject.next(null);
          }
        });
      }
    })

    return subject.pipe(finalize(() => {
      console.log('getOne: unsubscribe');
      if (!subject.observed) {
        unsubscribe();
        documentRefSubscription.unsubscribe();
      }
      else {
        console.log('We still have observers !');
      }
    }));
  }

  getOneByDocumentReference(docRef: DocumentReference<T>) {
    let subject = new ReplaySubject<T | null>(1);

    let unsubscribe: Unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        subject.next(doc.data());
      } else {
        subject.next(null);
      }
    });

    return subject.pipe(finalize(() => {
      console.log('getOne: unsubscribe');
      if (!subject.observed) {
        unsubscribe();
      }
      else {
        console.log('We still have observers !');
      }
    }));
  }

  getAll(): Observable<T[]> {
    return this.list({ limit: 0 }).pipe(map(g => g.result))
  }

  list(options: IListOptions<T> | null = null): Observable<IListResult<T>> {

    options ??= {
      limit: this.defaultLimitInList,
      filters: [],
      startAfter: null,
      endAt: null,
      orderBy: []
    }

    const whereOptions = options?.filters?.map(filter => { return where(filter.fieldPath, filter.opStr, filter.value) }) ?? [];
    const orderByOptions = options?.orderBy?.map(order => { return orderBy(order.field, order.direction as OrderByDirection) }) ?? [];

    const cursorOptions: any = [];
    if (options.startAfter != null) {
      cursorOptions.push(startAfter(options.startAfter));
    } else if (options.endAt != null) {
      cursorOptions.push(endAt(options.endAt));
    }

    // The subject that we will return
    let subject = new ReplaySubject<IListResult<T>>();
    let onSnapshotUnsubscribe: any;

    let colletionRef$ = this.getCollectionReference();

    let collectionSubscription = colletionRef$.subscribe({
      next: (collection) => {
        let queryConstraint: QueryConstraint[] = [];
        queryConstraint.push(...whereOptions);
        queryConstraint.push(...orderByOptions);
        queryConstraint.push(...cursorOptions);
        if (options?.limit) {
          queryConstraint.push(limit(options?.limit ?? this.defaultLimitInList));
        }
        const q = query(collection, ...queryConstraint);

        onSnapshotUnsubscribe = onSnapshot(q, (querySnapshot) => {
          const entities: T[] = [];

          let lastSnapshot: QueryDocumentSnapshot | null = null;

          querySnapshot.forEach((doc) => {
            const data = doc.data()
            entities.push(data);
            lastSnapshot = doc;
          });

          // We set the snapshot for the last element to be used for "startAfter"
          let paginationToken = lastSnapshot;

          subject.next({ result: entities, paginationToken });
        });
      }
    })

    return subject.pipe(finalize(() => {
      if (!subject.observed) {
        onSnapshotUnsubscribe();
        collectionSubscription.unsubscribe();
        console.log('list: unsubscribe');
      }
    }));
  }

  async upsertAsync(item: T) {
    const docRef = await firstValueFrom(this.getDocumentReference(item.id));
    if (!item.id) {
      item.owner = this.owner;
      item.ownerId = this.ownerId;
      item.lastModifiedByEmail = this.owner;
      item.creationDate = new Date();
    }
    else {
      item.lastModificationDate = new Date();
    }

    await setDoc(docRef, item, { merge: true });
  }


  async addAsync(item: T) {
    // Add a new document in the collection
    item.owner = this.owner;
    item.ownerId = this.ownerId;
    item.lastModifiedByEmail = this.owner;
    item.creationDate = new Date();

    if (item.id) {
      const docRef = await firstValueFrom(this.getDocumentReference(item.id));
      setDoc(docRef, item);
    } else {
      const collectionRef = await firstValueFrom(this.getCollectionReference());
      item.id = (await addDoc(collectionRef, item)).id;
    }
    return item;
  }

  /**
   * Partial update of a document
   * @param id
   * @param data
   */
  async partialUpdate(id: string, data: any) {
    data.lastModifiedByEmail = this.owner;
    data.lastModificationDate = new Date().toISOString();

    // We remove all undefined fields that are not supported by firebase
    data = this.removeEmpty(data);

    const docRef = await firstValueFrom(this.getDocumentReference(id));
    await updateDoc(docRef, data);
  }

  async deleteAsync(id: string) {
    const docRef = await firstValueFrom(this.getDocumentReference(id));
    await deleteDoc(docRef);
  }

  /**
 * Recursively removes properties with undefined values from an object or array.
 * @param obj - The input object or array to be cleaned.
 * @returns A new object or array without properties or elements with undefined values.
 */
  private removeEmpty = (obj: any): any => {
    // Check if the input is an array or an object
    const isArray = Array.isArray(obj);
    let newObj: any = isArray ? [] : {};

    Object.keys(obj).forEach((key) => {
      // If the value is an object or an array, recursively clean it
      if (obj[key] === Object(obj[key])) {
        newObj[key] = this.removeEmpty(obj[key]);
      }
      // If the value is not undefined, add it to the new object or array
      else if (obj[key] !== undefined) {
        newObj[key] = obj[key];
      }
    });

    // If it's an array, filter out any undefined elements that might have been added during the recursion
    if (isArray) {
      newObj = newObj.filter((element: any) => element !== undefined);
    }

    return newObj;
  };

}
