import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IHotelList } from '../../services/hotel-unifieddata-api/dtos/hotel-list-dto';

export interface ISelectHotelListDialogData {
  hotelList: IHotelList[],
  currentHotel: IHotelList | null;
}

@Component({
  selector: 'app-select-hotel-list-dialog',
  templateUrl: './select-hotel-list-dialog.component.html',
  styleUrls: ['./select-hotel-list-dialog.component.scss']
})
export class SelectHotelListDialogComponent implements OnInit {

  public hotels: IHotelList[] = [];
  public value: IHotelList | null = null;

  constructor(
    public modalDialogRef: MatDialogRef<SelectHotelListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectHotelListDialogData,
  ) {
  }

  async ngOnInit() {
    this.hotels = this.data.hotelList;
    if (this.data.currentHotel) {
      this.value = this.data.currentHotel;
    } else {
      this.value = this.data.hotelList.length > 0 ? this.data.hotelList[0] : null;
    }
  }

  async saveHotel() {
    this.modalDialogRef.close({
      hotelList: this.hotels,
      currentHotel: this.value
    });
  }
}
