<div>
  <h2 class="hide-mobile">
    <span class="material-icons">report</span>
    {{ 'MODAL.SELECT_HOTEL.TITLE' | translate }}
  </h2>

  <h3 class="hide-mobile" [innerHTML]="'MODAL.SELECT_HOTEL.SUBTITLE' | translate"></h3>

  <h3 class="hide-desktop" [innerHTML]="'MODAL.SELECT_HOTEL.MOBILE_TITLE' | translate"></h3>

  <div class="select-div">

    <p class="hide-mobile">{{ 'MODAL.SELECT_HOTEL.NOTICE' | translate }}</p>

    <mat-form-field appearance="fill" class="mat-select-casino">
      <mat-select [(ngModel)]="value" id="casino-selection">
        <mat-option [value]="{code: '', name: ''}"></mat-option>
        <mat-option *ngFor="let hotel of hotels" id="{{ hotel.code }}" [value]="hotel">{{ hotel.name }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <button mat-button class="cta-primary button-dimensions" id="select-casino-button"
    [disabled]="value === null || value.code === ''" (click)="saveHotel()">
    <span class="material-icons">done</span>
    {{ 'MODAL.SELECT_HOTEL.BUTTON' | translate }}
  </button>

</div>
