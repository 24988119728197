import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToolbarTitleService {

  private _title: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _title$ = this._title.asObservable();


  changeTitle(title: string | null): void {
    this._title.next(title);
  }

  getTitle(): Observable<string | null> {
    return this._title$;
  }
}
