import { Component, Input, OnInit, OnDestroy, Optional, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DowngradedModeService } from './services/czam-api/downgraded-mode.service'
import { DowngradedDialogComponent } from './components/downgraded-dialog/downgraded-dialog.component';
import { filter, map, Observable, Subscription } from 'rxjs';
import { SystemService } from './services/czam-api/system.service';
import { ICzamSystemInfoDto } from './services/czam-api/interfaces/czam-system-info-dto';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { AuthenticationService } from './services/authentication.service';
import { CasinoService } from './services/casino.service';
import { ICasinoDto } from './dtos/casino-dto';
import { LogoutDialogComponent } from './components/logout/logout-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { GtmService } from './services/gtm.service';
import * as Sentry from "@sentry/angular";

@Component({
  selector: 'lib-czam-common',
  templateUrl: './czam-common.component.html',
  styleUrls: ['./czam-common.component.scss']
})
export class CzamCommonComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title = '';
  @Input() appName = '';
  @Input() withBackgroundLogo = false;
  @Input() setHeaderBackGround: boolean = true;

  private downgradedModalRef: MatDialogRef<DowngradedDialogComponent> | null = null;
  private subscriptions: Subscription[] = [];
  public backEndSystem: ICzamSystemInfoDto = {} as any;
  public currentTheme: string = "basic";
  public isLoggedIn: Observable<boolean>;
  public username: Observable<string>;
  public casino?: Observable<ICasinoDto | null>;
  private pageSpan: Sentry.Span | null = null;


  constructor(
    public router: Router,
    public dialog: MatDialog,
    public downgradedService: DowngradedModeService,
    private systemService: SystemService,
    private translator: TranslateService,
    private snackbar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private gtmService: GtmService,
    @Optional() public casinoService: CasinoService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.isLoggedIn = this.authenticationService.isLoggedIn.asObservable();
    this.username = this.authenticationService.getUsername();

    if (this.casinoService) {
      this.casino = this.casinoService.getCurrentCasino();
    }

    this.matIconRegistry.addSvgIcon(
      'rfid-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/icons/rfid-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'b-play-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/icons/b-play-logo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'ultimate-card-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/lib-common/images/card-ultimate.svg')
    );
  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.onPageLoadEnd(event);
    });

    // show Downgraded modal dialog when CZAM goes offline
    let sub = this.downgradedService.$isDowngraded.subscribe({
      next: (isOffline: boolean) => {
        // CZAM is offline
        if (isOffline) {
          // only show if not already popped-in
          if (this.downgradedModalRef == null || this.downgradedModalRef.getState() == MatDialogState.CLOSED) {
            this.openDowngradedDialog();
          }
        }
        // CZAM is online
        else {
          // only close if not already closed
          if (this.downgradedModalRef != null && this.downgradedModalRef.getState() == MatDialogState.OPEN) {
            this.closeDowngradedDialog();
          }
        }
      }
    });
    this.subscriptions.push(sub);
    sub = this.systemService.getParameters().subscribe(result => {
      this.backEndSystem = result;
    })
    this.subscriptions.push(sub);
    // show message to user when CZAM is back online
    sub = this.downgradedService.$isConnectionRestored.subscribe({
      next: (isRestored: boolean) => {
        // CZAM is back online !
        if (isRestored) {
          this.showConnectionRestored();
        }
      }
    });
    this.subscriptions.push(sub);
    // filter navigation events, update them according to route
    sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let theme = 'basic';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['theme']) {
            theme = route!.snapshot.data['theme'];
          }
          return theme;
        })
      )
      .subscribe((theme: string) => {
        this.currentTheme = theme;
      });
    this.subscriptions.push(sub);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.onPageLoadStarted(event);
      }
    });

  }

  // opens a modal that tell user that CZAM is in downgraded-mode (no internet or no connection to CZAM backend)
  private openDowngradedDialog(): void {
    this.downgradedModalRef = this.dialog.open(DowngradedDialogComponent, {
      width: '500px',
      height: '300px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
      disableClose: true // forbid user to close the dialog by clicking anywhere on screen
    })
  }

  // close the downgraded-mode modal
  private closeDowngradedDialog(): void {
    this.downgradedModalRef?.close();
    this.downgradedModalRef = null;
  }

  // show message to user when connection is restored
  private showConnectionRestored(): void {
    this.translator.get('SNACKBAR.LIVE_ON').subscribe(message => {
      this.snackbar.openFromComponent(
        SnackBarComponent, {
        data: { message: message, icon: 'wifi', class: 'success' },
        duration: 3000,
        panelClass: 'dark-snackbar-success'
      });
    });
  }

  // Open dialog to change casino
  public openSelectionDialog() {
    if (this.casinoService) {
      this.casinoService.openSelectCasinoDialog().then();
    }
  }

  public openLogoutDialog(): void {
    this.dialog.open(LogoutDialogComponent, {
      width: '500px',
      height: '300px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
    })
  }

  ngOnDestroy(): void {
    for(const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {

    this.gtmService.appName = this.appName;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(async (event: any) => {
      let e = event as NavigationEnd;
      await this.gtmService.sendPageViewTag(e.urlAfterRedirects);
    });
  }

  onPageLoadStarted(event: NavigationStart) {

    Sentry.startSpanManual({ name: event.url, op: 'ui.angular.routing' }, (span) => {
      this.pageSpan = span;

      console.log(`Page Load: ${event.url}, current span: ${JSON.stringify(span)}`);

      let rootSpan = Sentry.getRootSpan(span);
      console.log(`Page Load: ${event.url}, root span: ${JSON.stringify(rootSpan)}`);
    }
    );
  }

  onPageLoadEnd(event: NavigationEnd) {
    // Check if the URL contains 'version' in any segment
    if (event.url.includes('version')) {
      this.setHeaderBackGround = false; // Set your desired color
    }

    if (this.pageSpan != null) {
      this.pageSpan.end();
      this.pageSpan = null;
    }
  }

}
