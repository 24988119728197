import { FirestoreBaseEntity } from '../services/firestore-repository-base.service';
import { JobStep } from './job-step.class';
import { IdentityDocumentPicture } from "./client-draft.class";
import { Gender } from '../enums/gender.enum';
import { IIdentityDocumentDto } from '../dtos/Identity-document-dto';

export class IdScanJob extends FirestoreBaseEntity {
  currentStep = new JobStep();
  stepsDone?: number[];
  ciamId: string = '';
  firstNames?: string | null;
  lastName?: string | null;
  birthdate?: Date | null;
  identityDocumentPicture: IdentityDocumentPicture = new IdentityDocumentPicture();
  identityDocumentPictures: string[] = []
  identity: IIdentityDocumentDto = {} as IIdentityDocumentDto;
  gender: Gender | null = null;
  language: string = '';
  nationality: string = '';
  addressLine?: string;
  address2?: string;
  addressCity: string = '';
  emailNotifications?: boolean = false;
  postalNotifications?: boolean = false;
  smsNotifications?: boolean = false;
  partnersConsent?: boolean = false;
  cguConsent?: boolean = false;
  addressPostalCode: string = '';
  addressCountry: string = '';
  preferredFirstName: string = '';
  preferredLastName: string = '';
  urlSignature?: string | null;
  signatureBase64?: string | null;
  ariadDocumentId: string = '';

  /**   
   * * Assign all the values from the given parameter to the object properties when available   *
   * @param value   */
  assign(value: any): IdScanJob {
    return Object.assign(this, value);
  }

}
