import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, PasswordForgottenDialogComponent, SnackBarComponent } from 'lib-common';
import { Subscription, filter, first } from 'rxjs';

enum LoginState {
  Unknown,
  LoginWithEmail,
  LoginWithGoogle
}


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class LoginPageComponent implements OnInit, OnDestroy {

  public hidePassword: boolean = true;
  public loginControl: FormControl;
  public domainControl = new FormControl('groupebarriere.com');
  public passwordControl: FormControl;
  public isLoadingLoginWithGoogle: boolean = false;
  public isLoadingLoginWithEmail: boolean = false;
  public LoginState = LoginState; // Export enum to view
  public currentLoginState: LoginState = LoginState.Unknown;
  public currentEmail = "";

  private subscriptions: Subscription[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public route: ActivatedRoute) {
    this.loginControl = new FormControl('', [Validators.required]);
    this.passwordControl = new FormControl('', [Validators.required]);
  }

  ngOnInit(): void {

    console.log("Check if user is logged in");

    this.authenticationService.isLoggedIn.pipe(filter(g => g == true), first()).subscribe({
      next: async (isLoggedIn) => {
        console.log("IsloggedIn status:", isLoggedIn);
        if (isLoggedIn) {
          await this.onLoginSuccess();
        }
      }
    })

    this.domainControl.valueChanges.subscribe(newDomain => {
      console.log(`Domain changed to: ${newDomain}`);
    });


  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  async onLoginSuccess() {
    this.route.queryParams.subscribe(params => {
      if (params['redirectUrl']) {
        window.history.replaceState(null, '', params['redirectUrl']);
        window.location.href = params['redirectUrl'];
      }
      else {
        this.router.navigate(['/']);
      }
    });
  }

  async loginWithGoogle(): Promise<void> {

    this.currentLoginState = LoginState.LoginWithGoogle;
    this.isLoadingLoginWithGoogle = true;

    try {
      await this.authenticationService.loginWithGoogleAsync();
      this.currentLoginState = LoginState.Unknown;
    }
    catch (error: any) {
      console.log("Login failed : ", error);
      this.loginControl.setErrors(null);
      this.passwordControl.reset();
      this.passwordControl.setErrors(null);
      this.snackbar.openFromComponent(
        SnackBarComponent, {
        data: { message: 'Erreur lors de l\'authentification', icon: 'error', class: 'error' },
        duration: 3000,
        panelClass: 'dark-snackbar-error'
      });
      this.currentLoginState = LoginState.Unknown;
    }

    this.isLoadingLoginWithGoogle = false;
  }

  async onLogin() {

    if (this.currentLoginState == LoginState.Unknown) {
      this.currentEmail = `${this.loginControl.value}@${this.domainControl.value}`;
      this.currentLoginState = LoginState.LoginWithEmail;
      return;
    }

    if (this.loginControl.valid && this.passwordControl.valid) {


      const password = this.passwordControl.value;

      this.isLoadingLoginWithEmail = true;

      try {
        await this.authenticationService.login(this.currentEmail, password).then()
      }
      catch (error: any) {
        console.log("Login failed : ", error);
        this.loginControl.setErrors(null);
        this.passwordControl.reset();
        this.passwordControl.setErrors(null);
        this.snackbar.openFromComponent(
          SnackBarComponent, {
          data: { message: 'Erreur lors de l\'authentification', icon: 'error', class: 'error' },
          duration: 3000,
          panelClass: 'dark-snackbar-error'
        });
      }

      this.isLoadingLoginWithEmail = false;
    }
  }

  forgottenPassword(event: MouseEvent) {
    event.preventDefault(); //disable href behaviour

    let ref = this.dialog.open(PasswordForgottenDialogComponent, {
      maxWidth: '700px',
      maxHeight: '600px',
      minWidth: '320px',
      minHeight: '400px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
    });


    this.subscriptions.push(ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.authenticationService.forgottenPassword(this.currentEmail);
      }
    }));

  }

  editEmail() {
    this.currentLoginState = LoginState.Unknown;
  }
}
