import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-forgotten-dialog',
  templateUrl: './password-forgotten-dialog.component.html',
  styleUrls: ['./password-forgotten-dialog.component.scss']
})
export class PasswordForgottenDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  validate() {
  }
}
