import { Injectable, Optional } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { EMPTY, Observable, filter, map, mergeMap, of, switchMap } from 'rxjs';
import { fakeNotificationData } from './fake-notification-data';
import { INotificationCenterResultDto } from './dtos/notification-center-result-dto';
import { INotificationCenterDto } from './dtos/notification-center-dto';
import { CasinoService } from '../casino.service';
import { UtilsService } from "../utils.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {


  private urlBase = environment.notificationCenterApiUrl + '/notifications';
  private _notificationUncheckedNumber$: Observable<number> = new Observable<number>();
  get notificationUncheckedNumber$(): Observable<number> {
    return this._notificationUncheckedNumber$;
  }
  constructor(private http: HttpClient, @Optional() private casinoService: CasinoService, private utils: UtilsService) {

    if(casinoService && this.utils.isMobile()) {
      casinoService.currentCasino$.subscribe( casino => {
        casino?.code ? this.getUnnoticedNotificationsCount(casino.code) : null;
      });
    }
  }

  getNotifications(): Observable<INotificationCenterResultDto> {
    return of(fakeNotificationData);
  }

  getNotificationList(page: number): Observable<INotificationCenterResultDto> {

    if (this.casinoService) {
      // get casino
      return this.casinoService.getCurrentCasino()
        .pipe(
          filter((casino) => !!casino?.code), // Only proceed if casino and casino.code are valid
          switchMap((casino) => this.http.get<INotificationCenterResultDto>(`${this.urlBase}?page=${page}&place=${casino!.code}`)))
        .pipe(
          map((notificationResult: INotificationCenterResultDto) => {
              notificationResult.notifications.map((notification: INotificationCenterDto) => {
                const dateWithoutMillisecond = notification.creationDate.split('.')[0] + 'Z';
                notification.creationDateObject = new Date(dateWithoutMillisecond);
                return notification;
            });
            return notificationResult;
          }));
    } else {
      return EMPTY;
    }
  }

  private getUnnoticedNotificationsCount(casinoCode: string): void {
    this._notificationUncheckedNumber$ = this.http.get<number>(`${this.urlBase}/not-noticed/count?place=${casinoCode}`);
  }
}
