import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';


@Component({
  selector: 'app-header-modal',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<LogoutDialogComponent>
  ) { }

  ngOnInit() {
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();

    this.translateService.get('HEADER.SNACKBAR_MESSAGE').subscribe(message => {
      this.snackbar.openFromComponent(
        SnackBarComponent, {
        data: { message: message, icon: 'waving_hand', class: 'success' },
        duration: 3000,
        panelClass: 'dark-snackbar-success'
      });
    });

  }
}
