import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasinoService} from "./services/casino.service";

@NgModule({
  imports: [CommonModule],
})
export class CasinoServiceModule {
  static forRoot(): ModuleWithProviders<CasinoServiceModule> {
    return {
      ngModule: CasinoServiceModule,
      providers: [CasinoService],
    };
  }
}
