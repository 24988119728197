import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { Observable, Subscription } from "rxjs";
import {
  AuthenticationService,
  UtilsService,
  SystemService,
  ICzamSystemInfoDto,
  CasinoService,
  ICasinoDto
} from "lib-common";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

  version: string = environment.version;
  isMobile: boolean = false;
  public casino?: Observable<ICasinoDto | null>;

  public backEndSystem: ICzamSystemInfoDto = {} as any;
  getParamsSubscription: Subscription | null = null;

  constructor(private systemService: SystemService, private utils: UtilsService,
              public authService: AuthenticationService, private _casinoService: CasinoService) {
    this.systemService.getParameters().subscribe(result => {
      this.backEndSystem = result;
    })
  }

  get casinoService(): CasinoService {
    return this._casinoService;
  }

  ngOnInit() {
    this.isMobile = this.utils.isMobile();
  }

  resetClientHubLocalStorage() {
    localStorage.removeItem('clientHubSearch');
  }


  ngOnDestroy(): void {
    this.getParamsSubscription?.unsubscribe();
  }
}
