<mat-nav-list #actionButtonsBottomSheet>
  <ng-container *ngFor="let action of actionButtons">
    <ng-container *ngIf="action.isFeatureActive; else noFeature">
      <a mat-list-item *isFeatureActive="action.isFeatureActive" (click)="!action.disabled && closeBottomSheet(action)"
        [disabled]="action.disabled" [ngClass]="{ 'disabled' : action.disabled, 'hide': action.hide }">
        <span matListItemTitle><mat-icon [svgIcon]="action.isSvgIcon ? action.icon : ''">{{ action.icon }}</mat-icon>{{
          action.title }}</span>
      </a>
    </ng-container>
    <ng-template #noFeature>
      <a mat-list-item (click)="!action.disabled && closeBottomSheet(action)" [disabled]="action.disabled"
        [ngClass]="{ 'disabled' : action.disabled }">
        <span matListItemTitle><mat-icon [svgIcon]="action.isSvgIcon ? action.icon : ''">{{ action.icon }}</mat-icon>{{
          action.title }}</span>
      </a>
    </ng-template>
  </ng-container>
</mat-nav-list>