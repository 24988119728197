import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, collection, doc } from 'firebase/firestore';
import { FirestoreService } from './firestore.service';
import { CasinoService } from './casino.service';
import { AuthenticationService } from './authentication.service';
import { StepState } from '../enums/step-state.enum';
import { IStep } from '../interfaces/step.interface';
import { FirestoreRepositoryBase } from './firestore-repository-base.service';
import { UtilsService } from './utils.service';
import { IdScanJob } from '../models/id-scan-job.class';


@Injectable({
  providedIn: 'root'
})
export class IdScanJobService extends FirestoreRepositoryBase<IdScanJob> {

  private idScanSteps: IStep[] = [
    { title: 'La pièce d\'identité', state: StepState.Open },
    { title: 'Confirmation d\'informations', state: StepState.Open },
    { title: 'Signature de l\'adhésion', state: StepState.Open }
  ]

  constructor(
    firestoreService: FirestoreService,
    casinoService: CasinoService,
    authenticationService: AuthenticationService,
    private utilsService: UtilsService
  ) {
    super(IdScanJob, casinoService.getCurrentCasino().pipe(map(casino => {
      return collection(doc(collection(firestoreService.db, 'casinos'), <string>casino?.code), 'idScanJobs').withConverter(this);
    })), authenticationService.user);
   }

   public getIdScanSteps(): IStep[] {
    return this.idScanSteps
  }

  public override toFirestore(doc: IdScanJob) {
    let ret = super.toFirestore(doc);

    if (doc?.identity?.dateOfBirth) { ret.identity.dateOfBirth = doc.identity.dateOfBirth?.toISOString(); }
    if (doc?.identity?.deliveryDate) { ret.identity.deliveryDate = doc.identity.deliveryDate?.toISOString(); }
    if (doc?.identity?.validityEndDate) { ret.identity.validityEndDate = doc.identity.validityEndDate?.toISOString(); }
    if (doc?.identity?.validityStartDate) { ret.identity.validityStartDate = doc.identity.validityStartDate?.toISOString(); }

    return ret;
  }

  public override fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): IdScanJob {
    let object = super.fromFirestore(snapshot, options)
    if (object?.identity?.dateOfBirth) { object.identity.dateOfBirth = new Date(object.identity.dateOfBirth); }
    if (object?.identity?.deliveryDate) { object.identity.deliveryDate = new Date(object.identity.deliveryDate); }
    if (object?.identity?.validityEndDate) { object.identity.validityEndDate = new Date(object.identity.validityEndDate); }
    if (object?.identity?.validityStartDate) { object.identity.validityStartDate = new Date(object.identity.validityStartDate); }
    return object;
  }

  /**
     * Partial update of a document
     * @param id
     * @param data
     */
  override async partialUpdate(id: string, doc: any) {

    if (doc?.identity?.dateOfBirth) { doc.identity.dateOfBirth = this.utilsService.toyyyymmdd(doc.identity.dateOfBirth) }
    if (doc?.identity?.deliveryDate) { doc.identity.deliveryDate = this.utilsService.toyyyymmdd(doc.identity.deliveryDate) }
    if (doc?.identity?.validityEndDate) { doc.identity.validityEndDate = this.utilsService.toyyyymmdd(doc.identity.validityEndDate) }
    if (doc?.identity?.validityStartDate) { doc.identity.validityStartDate ? doc.identity.validityStartDate = this.utilsService.toyyyymmdd(doc.identity.validityStartDate) : null }

    await super.partialUpdate(id, doc);

  }


}
