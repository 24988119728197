<div class="login-page">

  <div class="login-illustration-container">
    <img class="logo-czam" src="assets/lib-common/images/logo-czam.svg" />
  </div>

  <div class="login-container">
    <div class="czam-desktop-title-container">
      <div class="title-icon">
        <img src="assets/lib-common/images/logo-czam.svg" />
      </div>
    </div>
    <div class="czam-phone-logo">
      <img src="assets/lib-common/images/logo-czam.svg" />
    </div>
    <form class="form-container" autocomplete="off">
      <div class="title-text">
        <h2>{{ 'LOGIN.WELCOME' | translate }}</h2>
        <h3>{{ 'LOGIN.CONNECT' | translate }}</h3>
      </div>

      <div class="login-form">

        <!-- new email input -->
        <mat-form-field appearance="fill" *ngIf="currentLoginState == LoginState.Unknown" class="email-field"
          floatLabel="always">
          <mat-label class="mat-label">Email</mat-label>

          <!-- Email Prefix Input -->
          <input id="login" name="login" matInput placeholder="" [formControl]="loginControl" required
            class="login-input">

          <!-- Dropdown for selecting the domain -->
          <mat-select [formControl]="domainControl" panelClass="domain-select-dropdown">
            <mat-option value="groupebarriere.com">@groupebarriere.com</mat-option>
            <mat-option value="cannesbarriere.com">@cannesbarriere.com</mat-option>
          </mat-select>

        </mat-form-field>


        <div id="email-validated" class="email-validated" *ngIf="currentLoginState == LoginState.LoginWithEmail"
          (click)="editEmail()">
          <span>{{currentEmail}}</span>
          <mat-icon class="gold" (click)="editEmail()">border_color</mat-icon>
        </div>

        <div [hidden]="currentLoginState != LoginState.LoginWithEmail">
          <mat-form-field appearance="fill">
            <mat-label class="mat-label">Mot de passe</mat-label>
            <input id="password" name="password" matInput [type]="hidePassword ? 'password' : 'text'"
              placeholder="Mot de passe" [formControl]="passwordControl">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword"
              [ngClass]="{'gold' : passwordControl.value != '','white' : passwordControl.value == ''}">{{hidePassword ?
              'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <div class="forbidden-container">
            <a (click)="forgottenPassword($event)" href="#">Première connexion ou mot de passe oublié?</a>
          </div>
        </div>


        <button id="continue-button" *ngIf="currentLoginState == LoginState.Unknown" type="button"
          class="cta-primary mt-10 mb-20 phone-mt-40 email-login-button" mat-flat-button
          [disabled]="isLoadingLoginWithEmail || loginControl.value == ''" (click)="onLogin()">
          <mat-icon *ngIf="isLoadingLoginWithEmail">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>Continuer
        </button>
        <!-- To keep form submit with keyboard -->
        <!-- [type]="currentLoginState == LoginState.Unknown ? 'button' : 'submit'"  -->
        <button id="connect-button" *ngIf="currentLoginState == LoginState.LoginWithEmail"
          class="cta-primary mt-10 mb-20 phone-mt-40 email-login-button" mat-flat-button type="submit"
          [disabled]="isLoadingLoginWithEmail || loginControl.value == ''" (click)="onLogin()">
          <mat-icon *ngIf="isLoadingLoginWithEmail">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>Se connecter
        </button>

      </div>

      <div *ngIf="currentLoginState == LoginState.Unknown" class="login-method-separator">Ou</div>

      <div *ngIf="currentLoginState == LoginState.Unknown || currentLoginState == LoginState.LoginWithGoogle"
        class="button-container">
        <button type="button" class="cta-secondary google-button" mat-flat-button [disabled]="isLoadingLoginWithGoogle"
          (click)="loginWithGoogle()">
          <img *ngIf="!isLoadingLoginWithGoogle" class="google-icon" src="assets/lib-common/icons/google-color.svg">
          <mat-icon *ngIf="isLoadingLoginWithGoogle" class="mr-5">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>{{ 'LOGIN.CONNECT_WITH_GOOGLE' | translate }}
        </button>
      </div>

      <div class="copyright text-light">
        <span>© CZAM 2022</span>
      </div>
    </form>
  </div>
</div>
