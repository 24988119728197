import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-version-page',
  templateUrl: './version-page.component.html',
  styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent {

  environment: any = environment;

}
