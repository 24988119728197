import { initializeApp } from 'firebase/app';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService, CzamCommonInitService, EnvironmentService, FirestoreService, ICzamSystemInfoDto, SystemService, WebPushNotificationsService } from 'lib-common';
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';


export class InitService extends CzamCommonInitService {

  constructor() {
    super();
  }

  public static async init(authenticationService: AuthenticationService,
    systemService: SystemService,
    firestoreService: FirestoreService,
    webpushService: WebPushNotificationsService,
    googleTagManagerConfiguration: GoogleTagManagerConfiguration,
    environmentService: EnvironmentService) {

    let systemInfo: ICzamSystemInfoDto = {
      environment: '',
      version: '',
      commitHash: '',
      installationDate: null,
      pushPublicKey: '',
      firebaseApiKey: '',
      firebaseAuthDomain: '',
      defaultCasinoCode: '',
      deviceManagerInstallerUrl: '',
      czamClientDocumentStoreBucketName: '',
      gcpProjectId: '',
      availablePrinters: [],
      availableTicketPrinters: []
    };

    // Get firebase information from czam
    try {
      systemInfo = await firstValueFrom(systemService.getParameters());
    }
    catch (exception) {
      // TODO: Enhance the error management
      console.log("Error: Cannot get system information:", exception);
      return;
    }

    await super.baseInit(webpushService, googleTagManagerConfiguration, environmentService);

    var firebaseConfig = {
      apiKey: systemInfo.firebaseApiKey,
      authDomain: systemInfo.firebaseAuthDomain,
      projectId: systemInfo.gcpProjectId,
      storageBucket: systemInfo.czamClientDocumentStoreBucketName
    }

    // Init firebase
    let app = initializeApp(firebaseConfig);
    firestoreService.init(app, systemInfo.environment);
    authenticationService.init();
  }
}
