import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string | boolean> {

    // Select the <base> element from the DOM and get its 'href' attribute value
    const base = document.querySelector('base')?.getAttribute('href') || '/';
    let normalizedBase = base.endsWith('/') ? base.slice(0, -1) : base;

    // Return an observable that emits a string or boolean value
    return this.authenticationService.isLoggedIn.pipe(map((isLoggedIn: boolean) => {
      // If the user is logged in, return the value of isLoggedIn
      // Otherwise, redirect the user to the login page with the current URL as the redirectUrl
      if (isLoggedIn) {
        return true;
      }
      else {

        if (base.startsWith('/czam')) {
          // Using internal Angular router for redirection
          this.router.navigate(['/login'], { queryParams: { redirectUrl: normalizedBase + state.url } });
        } else {
          // Using window.location.href for redirection
          window.location.href = '/czam/login?redirectUrl=' + normalizedBase + state.url;
        }

        return false;
      }
    }));
  }

}
