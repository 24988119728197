import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';
import { WebPushNotificationsService } from './notification-center-api/webpush-notifications.service';
import { EnvironmentService } from './environment.service';
import * as Sentry from "@sentry/angular";

export class CzamCommonInitService {

  constructor() { }

  public static async baseInit(webpushService: WebPushNotificationsService,
    googleTagManagerConfiguration: GoogleTagManagerConfiguration,
    environmentService: EnvironmentService) {
    webpushService.init();


    const environmentConfiguration = await environmentService.getEnvironmentConfiguration();

    googleTagManagerConfiguration.set({
      id: environmentConfiguration.googleTagManagerContainerId,
      gtm_auth: environmentConfiguration.googleTagManagerAuth,
      gtm_preview: environmentConfiguration.googleTagManagerPreview,
      googleTagManagerMode: environmentConfiguration.googleTagManagerMode
    });


    Sentry.init({
      dsn: "https://b989bf1d59e88d62c5edf1c7f8a4c8b0@o4507571941736448.ingest.de.sentry.io/4507571945144400",
      integrations: [
        //Sentry.browserTracingIntegration( /*{ idleTimeout: 5000, finalTimeout: 5000 }*/),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [
            /^https:\/\/apps.glbr.net\/.*/,
            /^http:\/\/localhost.*/,
            /^https:\/\/apps-.*/,
          ],
        }),
        // The following is all you need to enable canvas recording with Replay
        Sentry.replayCanvasIntegration(),

        // See https://docs.sentry.io/platforms/javascript/guides/react/user-feedback/configuration/
        // For feedback configuration
        /*
        Sentry.feedbackIntegration({
          // Additional SDK configuration goes in here, for example:
          colorScheme: "system",
        }),*/
        //Sentry.browserProfilingIntegration(),

      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture a percentage of the transactions
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      sendDefaultPii: true, // This option is required for capturing headers and cookies.
      environment: environmentConfiguration.stage,
      normalizeDepth: 5,
    });
  }
}