import { Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { FirebaseStorage, UploadResult, getBytes, getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class FireBaseStorageService {

  public storage!: FirebaseStorage;

  constructor() { }

  async init(app: FirebaseApp, env: string) {
    console.log("init", app);
    this.storage = getStorage(app);
    // if (env == 'Local') {
    //   // change to emulator if needed
    //   connectFirestoreEmulator(this.db, 'localhost', 8060);
    // }
  }

  async uploadFileAsync(file: File, fullPath: string): Promise<UploadResult> {
    const storageRef = ref(this.storage, `${fullPath}`);
    // 'file' comes from the Blob or File API
    return await (uploadBytes(storageRef, file));
  }

  async getFileAsync(fullPath: string) {
    console.log(this.storage)
    const storageRef = ref(this.storage, `${fullPath}`);
    // 'file' comes from the Blob or File API
    return await getBytes(storageRef);
  }

  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = "";
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  async getFileAsBase64Async(fullPath: string): Promise<string> {
    return this.arrayBufferToBase64(await this.getFileAsync(fullPath));
  }

  async deleteFileAsync(fullPath: string) {
    const storageRef = ref(this.storage, `${fullPath}`);
    return await deleteObject(storageRef);
  }

}
