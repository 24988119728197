import { Pipe, PipeTransform } from '@angular/core';
import { WalletLastOperationType } from '../services/wallet-api/enums/wallet-last-operation-type.enum';

@Pipe({
  name: 'sign'
})
export class SignPipe implements PipeTransform {
  transform(type: WalletLastOperationType): string {
    switch (type) {
      case WalletLastOperationType.CashOut:
        return '+';
      case WalletLastOperationType.CashIn:
        return '-';
      case WalletLastOperationType.PayOut:
        return '-';
      case WalletLastOperationType.PayIn:
        return '+';
      case WalletLastOperationType.PayOutCashDeskDetail:
        return '-';
      case WalletLastOperationType.PayInCashDeskDetail:
        return '+';
      case WalletLastOperationType.Tip:
        return '-';
      default:
        return '';
    }
  }
}
