import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/***
 * This component is used to show a modal dialog on top of the App
 * when the frontend loses connection and switches to downgraded-mode
 */
@Component({
  selector: 'app-downgraded-modal',
  templateUrl: './downgraded-dialog.component.html',
  styleUrls: ['./downgraded-dialog.component.scss']
})
export class DowngradedDialogComponent implements OnInit {

  @Output() isManualClosed = new EventEmitter<boolean>();

  public constructor(private modalDialogRef: MatDialogRef<DowngradedDialogComponent>) {
  }

  public onManualClose(): void {
    this.isManualClosed.emit(true);
    this.modalDialogRef.close();
  }

  public ngOnInit(): void {
    this.isManualClosed.emit(false);
  }
}