import { Injectable } from '@angular/core';
import { Observable, combineLatest, map} from "rxjs";
import { CasinoService } from "./casino.service";
import { AuthenticationService } from "./authentication.service";
import { Role } from "../enums/role.enum";
import { CasinoRole } from "../models/casino-role.class";

@Injectable({
    providedIn: 'root'
})
export class RoleGuardCheckService {


    constructor(private casinoService: CasinoService, private authenticationService: AuthenticationService) {
    }


    getRoleGuard(roles: Role[]): Observable<boolean> {
        return combineLatest([this.casinoService.currentCasino$, this.authenticationService.roles$]).pipe(
            map(([currentCasino, currentUserRoles]) => {

                if (null === roles) {
                    return false;
                }

                if (roles.length === 0) {
                    return true;
                }

                if (currentUserRoles === null || currentUserRoles.length == 0) {
                    return false;
                }

              let currentUserRolesFiltered: CasinoRole[] = currentUserRoles.filter(role => (role.casinoCode == currentCasino?.code || (role.casinoCode == "" || role.casinoCode == '*')));
              let matchingRoles = currentUserRolesFiltered.filter(casinoRoles => roles.find(role => role == casinoRoles.role));

                return (matchingRoles && matchingRoles.length > 0);
            })
        )
    }
}
