<div class="access-denied">
  <div class="access-denied-img-container">
    <div class="access-denied-img"></div>
  </div>
  <div class="access-denied-text-container">
    <div class="access-denied-title">{{ 'ACCESS_DENIED.TITLE' | translate }}</div>
    <div class="access-denied-message">{{ 'ACCESS_DENIED.MESSAGE_PAGE' | translate }}</div>
  </div>
  <div class="buttons-container">
    <button mat-button class="cta-primary py-50 white-space-no-wrap"
            (click)="redirectToHome()">
      {{ 'ACCESS_DENIED.HOME_BUTTON' | translate }}
    </button>
  </div>
</div>
