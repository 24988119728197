import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { DowngradedModeService } from './czam-api/downgraded-mode.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private isErrorActive: boolean = false;
  constructor(private _snackBar: MatSnackBar, private _translate: TranslateService) {}

  openSnackBar(message: string, action: string, durationInMs: number) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration: durationInMs,
      panelClass: 'dark-snackbar-error',
      data: { message, action, icon: 'error', class: 'error' }
    });
  }

  public Active(activeError: boolean) {
    this.isErrorActive = activeError;
  }

  public error(err: any) {
    console.error(err);
    if (!this.isErrorActive) {
      return;
    }
    if (typeof err?.error?.code === 'string' && typeof err?.error?.description === 'string') {
      var msg = this._translate.instant("ERROR." + err.error.code)
      if (msg == "ERROR." + err.error.code) {
        msg = err.error.description
      }
      this.openSnackBar(`${err.error.code} : ${msg}`, 'Fermer', 7000);
    }
    else if (typeof err === 'string') {
      this.openSnackBar(err, 'Fermer', 7000);
    }
    else {
      this.openSnackBar("Une erreur est survenue.", 'Fermer', 7000);
    }

  }

  public errorAndDisplayMessage(err: any) {
    console.error(err);
    this.openSnackBar(err, 'Fermer', 5000);
  }

}
