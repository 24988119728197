import { FidelityLevel } from "../../enums/fidelity-level.enum";
import { INotificationCenterResultDto } from "./dtos/notification-center-result-dto";
import { NotificationCenterStatus } from "./enums/notification-center-status.enum";
import { NotificationCenterType } from "./enums/notification-center-type.enum";

export const fakeNotificationData: INotificationCenterResultDto = {
  count: 12,
  pageNumber: 1,
  pageSize: 100,
  notifications: [
    {
        "id": "1",
        "type": NotificationCenterType.CasinoEntryLoyaltyLevel,
        "title": "Entrée client",
        "content": "Content 1",
        "url": "https://notif.com/1",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": false,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "304098",
        "customerFirstName": "Marc",
        "customerLastName": "Houllegatte",
        "customerLoyaltyLevel": FidelityLevel.Unknown,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "2",
        "type": NotificationCenterType.CasinoEntryLoyaltyLevel,
        "title": "Entrée client",
        "content": "Content 2",
        "url": "https://notif.com/2",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_2",
        "customerFirstName": "Martin",
        "customerLastName": "Dupont",
        "customerLoyaltyLevel": FidelityLevel.Silver,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "3",
        "type": NotificationCenterType.CasinoEntryHighPotentialPlayer,
        "title": "Potential client",
        "content": "Content 3",
        "url": "https://notif.com/3",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "customer_3",
        "customerFirstName": "Martin",
        "customerLastName": "Dupont",
        "customerLoyaltyLevel": FidelityLevel.Black,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "4",
        "type": NotificationCenterType.LoyaltyLevelUpgrade,
        "title": "Upgrade",
        "content": "Content 4",
        "url": "https://notif.com/4",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_4",
        "customerFirstName": "Martin",
        "customerLastName": "Dupont",
        "customerLoyaltyLevel": FidelityLevel.Ultimate,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "5",
        "type": NotificationCenterType.CasinoEntryChurn,
        "title": "Churn client",
        "content": "Content 5",
        "url": "https://notif.com/5",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_5",
        "customerFirstName": "FirstName_5",
        "customerLastName": "LastName_5",
        "customerLoyaltyLevel": FidelityLevel.Gold,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "6",
        "type": NotificationCenterType.CasinoEntryAddiction,
        "title": "Addiction client",
        "content": "Content 6",
        "url": "https://notif.com/6",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": false,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_6",
        "customerFirstName": "FirstName_6",
        "customerLastName": "LastName_6",
        "customerLoyaltyLevel": FidelityLevel.White,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "7",
        "type": NotificationCenterType.CasinoEntryCustomerBirthday,
        "title": "Anniversaire",
        "content": "Content 7",
        "url": "https://notif.com/7",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_7",
        "customerFirstName": "FirstName_7",
        "customerLastName": "LastName_7",
        "customerLoyaltyLevel": FidelityLevel.Black,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "8",
        "type": NotificationCenterType.CasinoEntryLoyaltyLevel,
        "title": "Entrée client",
        "content": "Content 8",
        "url": "https://notif.com/8",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "customer_8",
        "customerFirstName": "FirstName_8",
        "customerLastName": "LastName_8",
        "customerLoyaltyLevel": FidelityLevel.Black,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "9",
        "type": NotificationCenterType.CasinoEntryLoyaltyLevel,
        "title": "Entrée client",
        "content": "Content 9",
        "url": "https://notif.com/9",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.None,
        "customerKey": "customer_9",
        "customerFirstName": "FirstName_9",
        "customerLastName": "LastName_9",
        "customerLoyaltyLevel": FidelityLevel.Black,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "10",
        "type": NotificationCenterType.CasinoEntryCustomerBirthday,
        "title": "Anniversaire",
        "content": "Content 10",
        "url": "https://notif.com/10",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": false,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "customer_10",
        "customerFirstName": "FirstName_10",
        "customerLastName": "LastName_10",
        "customerLoyaltyLevel": FidelityLevel.Ultimate,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "11",
        "type": NotificationCenterType.CasinoEntryLoyaltyLevel,
        "title": "Entrée client",
        "content": "Content 11",
        "url": "https://notif.com/11",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": true,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "customer_11",
        "customerFirstName": "FirstName_11",
        "customerLastName": "LastName_11",
        "customerLoyaltyLevel": FidelityLevel.White,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    },
    {
        "id": "12",
        "type": NotificationCenterType.CasinoEntryChurn,
        "title": "Churn client",
        "content": "Content 12",
        "url": "https://notif.com/12",
        "creationDate": "2023-08-10T18:15:10",
        "creationDateObject": new Date("2023-08-10T18:15:10"),
        "noticed": false,
        "status": NotificationCenterStatus.Processed,
        "customerKey": "customer_12",
        "customerFirstName": "FirstName_12",
        "customerLastName": "LastName_12",
        "customerLoyaltyLevel": FidelityLevel.Silver,
        "ciamId": "AYiK96cALYeCFxtMTsPM"
    }
  ]
}