<div class="anim-elements">

  <h3 *ngIf="data.title" [innerHtml]="data.title"></h3>

  <h4 *ngIf="data.subtitle" [innerHtml]="data.subtitle"></h4>

  <p [innerHtml]="data.text"></p>

  <img *ngIf="data.imgUrl" [src]="data.imgUrl">

  <div class="button-container">
    <button mat-button (click)="cancel()" class="cta-secondary">
      <span class="material-icons">clear</span>{{data.noButtonCaption }}
    </button>
    <button mat-button (click)="confirm()" class="cta-primary">
      <span class="material-icons">check</span> {{ data.yesButtonCaption }}
    </button>
  </div>

</div>