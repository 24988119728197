export enum WalletLastOperationType {
  // tu joues cet argent dans la machine, c'est un -
  CashIn = 'CashIn',
  // tu récupères l'argent gagné dans ton wallet, c'est un +
  CashOut = 'CashOut',
  // tu mets de l'argent de ton compte bancaire dans ton wallet, c'est un +
  PayIn = 'PayIn',
  // tu récupères l'argent de ton wallet dans ton compte bancaire, c'est un -
  PayOut = 'PayOut',
  // tu mets de l'argent de ton compte bancaire dans ton wallet, c'est un +
  PayInCashDeskDetail = 'PayInCashDeskDetail',
  // tu récupères l'argent de ton wallet dans ton compte bancaire, c'est un -
  PayOutCashDeskDetail = 'PayOutCashDeskDetail',
  // c'est un -
  Tip = 'Tip'
}
