<mat-drawer-container class="drawer-container" [class.with-background-logo]="withBackgroundLogo" autosize>
  <mat-drawer #drawer class="sidenav" mode="over">

    <div class="side-nav-content">
      <div class="side-nav-content-top">

        <div class="drawer-logo-and-title logo">
          <img src="assets/lib-common/images/barriere-logo-couleurs.png" alt="app-logo">
        </div>

        <div>
          <p class="username">{{ username | async }}</p>
          <p mat-button *ngIf="casinoService && (casino | async)" class="casinoname"
            (click)="drawer.toggle();openSelectionDialog()">
            <span class="material-icons">maps_home_work</span>
            <span>{{ (casino | async)?.nom }}</span>
          </p>
<!--          <p mat-button *ngIf="(casino | async)" class="casinoname"-->
<!--             (click)="drawer.toggle();openSelectionDialog(ApplicationType.Hotel)">-->
<!--            <span class="material-icons">maps_home_work</span>-->
<!--            <span *ngIf="applicationTypes.includes(ApplicationType.Hotel)">{{ ApplicationType.Hotel }}</span>-->
<!--          </p>-->
        </div>

        <mat-divider class="mt-30"></mat-divider>
        <mat-nav-list *ngIf="isLoggedIn | async">
          <mat-list-item (click)="drawer.toggle()">
            <a href="/home/dashboard" [ngClass]="{'active': this.router.url.includes('/home')}">
              <mat-icon matListIcon>home</mat-icon>
              <span class="word">{{ 'MENU.MOBILE.HOME' | translate }}</span>
            </a>
          </mat-list-item>

          <mat-list-item (click)="openLogoutDialog();drawer.toggle()">
            <a>
              <mat-icon matListIcon>logout</mat-icon>
              <span class="word">{{ 'MENU.MOBILE.LOGOUT' | translate }}</span>
            </a>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div class="side-nav-content-bottom">
        <div class="version" *ngIf="backEndSystem" (click)="drawer.toggle()" class="version">
          <a href="/czam/version"><span>Version&nbsp;{{backEndSystem['version']}}</span></a>
        </div>
      </div>
    </div>
  </mat-drawer>


  <div class="app-container" [ngClass]="{'unlogged': !(isLoggedIn | async)}">
    <div class="header" *ngIf="(isLoggedIn | async) == true">
      <app-header (toggleSideNavEvent)="drawer.toggle()" [ngClass]="{'header-background-color': setHeaderBackGround}"
        [title]="title" [theme]="currentTheme"></app-header>
    </div>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
