import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs";
import { collection, doc } from "firebase/firestore";
import { LvaCreationJob } from "../models/lva-creation-job.class";
import { FirestoreRepositoryBase } from "./firestore-repository-base.service";
import { IStep } from "../interfaces/step.interface";
import { StepState } from "../enums/step-state.enum";
import { FirestoreService } from "./firestore.service";
import { CasinoService } from "./casino.service";
import { AuthenticationService } from "./authentication.service";
import { UtilsService } from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class LvaCreationJobService extends FirestoreRepositoryBase<LvaCreationJob> {

  private stepsForCreation: IStep[] = [
    {title: 'Détails sur la LVA', state: StepState.Open},
    {title: 'Aperçu du contrat', state: StepState.Open},
    {title: 'Signature', state: StepState.Open},
    {title: 'Impression', state: StepState.Open},
  ]


  constructor(firestoreService: FirestoreService,
              casinoService: CasinoService,
              authenticationService: AuthenticationService,
              private httpClient: HttpClient,
              private utilsService: UtilsService) {
    super(LvaCreationJob, casinoService.getCurrentCasino().pipe(map(casino => {
      return collection(doc(collection(firestoreService.db, 'casinos'), <string>casino?.code), 'lvaCreationJob').withConverter(this);
    })), authenticationService.user);
  }


  public getCreationSteps(): IStep[] {
    return this.stepsForCreation;
  }

  /**
   * Delete async
   * @param id
   */
  override async deleteAsync(id: string): Promise<void> {
    await super.deleteAsync(id);
  }

}
