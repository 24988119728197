import { Component, OnInit } from '@angular/core';

import { Homonyms } from '../../../enums/homonyms.enum';
import { RestrictedEntryType } from "../../../enums/restricted-entry-type.enum";
import { SilentNotificationType } from "../../../enums/silent-notification-type.enum";
import { CheckInItemType } from "../../../enums/check-in-item-type.enum";
import { Gender } from "../../../enums/gender.enum";
import { ClientSearchSource } from "../../../enums/client-search-source.enum";
import { EntrySource } from "../../../enums/entry-source.enum";
import { ClientState } from "../../../enums/client-state.enum";
import { ICheckInItemDto } from "../../../services/czam-api/dtos/check-in-item-dto";
import { IIdentityDocumentDto } from "../../../dtos/Identity-document-dto";
import { IClientDto } from "../../../dtos/client-dto.interface";


let birthDate = new Date();
birthDate.setFullYear(1980);

@Component({
  selector: 'app-client-card-examples',
  templateUrl: './client-card-examples.component.html',
  styleUrls: ['./client-card-examples.component.scss']
})
export class ClientCardExamplesComponent implements OnInit {

  public Homonyms = Homonyms;

  public under18CheckInItem = this.getDefaultClient({
    firstNames: "Hichem",
    dateOfBirth: new Date("2010-09-28"),
    birthName: "Taoufik",
    preferredFamilyName: '',
    preferredGivenName: '',
    ciamId: '',
    placeOfBirth: 'Hambourg',
    restrictedEntryType: RestrictedEntryType.Unknown,
    silentNotificationType: SilentNotificationType.Unknown,
    type: CheckInItemType.Unknown,
    gender: Gender.Male,
    restrictionId: 0,
    isLva: false,
    clientSearchSource: ClientSearchSource.Manual,
    entrySource: EntrySource.CheckIn,
    state: ClientState.Active,
    playerId: 0
  })

  public birthDayCheckInItem = this.getDefaultClient({
    firstNames: "Hichem",
    dateOfBirth: birthDate,
    birthName: "Taoufik",
    preferredGivenName: '',
    preferredFamilyName: '',
    ciamId: '',
    placeOfBirth: 'Hambourg',
    restrictedEntryType: RestrictedEntryType.Unknown,
    silentNotificationType: SilentNotificationType.Unknown,
    type: CheckInItemType.Unknown,
    gender: Gender.Male,
    restrictionId: 0,
    isLva: false,
    clientSearchSource: ClientSearchSource.Manual,
    entrySource: EntrySource.CheckIn,
    state: ClientState.Active,
    playerId: 0
  })

  public imCheckInItem = this.getDefaultClient({
    firstNames: "Hichem",
    dateOfBirth: new Date("1980-09-28"),
    birthName: "Taoufik",
    preferredFamilyName: '',
    preferredGivenName: '',
    ciamId: '',
    placeOfBirth: 'Hambourg',
    restrictedEntryType: RestrictedEntryType.Im,
    silentNotificationType: SilentNotificationType.Unknown,
    type: CheckInItemType.Restricted,
    gender: Gender.Male,
    restrictionId: 0,
    restrictionStartDate: new Date("2001-01-01"),
    restrictionEndDate: new Date("2025-01-01"),
    isLva: false,
    clientSearchSource: ClientSearchSource.Manual,
    entrySource: EntrySource.CheckIn,
    state: ClientState.Inactive,
    playerId: 0
  })

  public clientCheckInItem = this.getDefaultClient({
    ciamId: 'AYGAX6s40F_0Y1Nxhh-z',
    type: CheckInItemType.Client,
  } as ICheckInItemDto);

  public clientCheckInItem2 = this.getDefaultClient({
    ciamId: '2',
    type: CheckInItemType.Client,
  } as ICheckInItemDto);


  public clientCheckInItem3 = this.getDefaultClient({
    ciamId: '3',
    type: CheckInItemType.Client,
  } as ICheckInItemDto);

  public clientCheckInItem4 = this.getDefaultClient({
    ciamId: '4',
    type: CheckInItemType.Client,
  } as ICheckInItemDto);

  constructor() { }

  ngOnInit(): void {
  }
  getDefaultClient(checkInItem: ICheckInItemDto) {
    return {
      identity: {
        dateOfBirth: checkInItem?.dateOfBirth,
        placeOfBirth: checkInItem?.placeOfBirth
      } as IIdentityDocumentDto,
      preferredFirstName: checkInItem?.firstNames,
      preferredLastName: checkInItem?.birthName,
      restrictedEntryType: checkInItem?.restrictedEntryType,
      silentNotificationType: checkInItem?.silentNotificationType,
      restrictionId: checkInItem?.restrictionId,
      restrictionStartDate: checkInItem?.restrictionStartDate,
      restrictionEndDate: checkInItem?.restrictionEndDate
    } as IClientDto
  }
}
