<div class="search-result-container">
  <div class="client-selected-container">
    <h2>{{ 'SEARCH_RESULT.TITLE' | translate }}</h2>
    <div class="client-selected-container-result">
      <lib-client-card *ngIf="loadedClient" [hasINJHomonymsToCheck]="hasHomonymsWithRestrictionToCheck"
                       [hasHomonyms]="hasHomonyms" [client]="loadedClient!"
                       [isLoading]="clientLoading" [form]="form" [clientError]="clientError"
                       [checkInSearchOrigin]="checkInSearchOrigin" (confirmNoHomonymsInList)="confirmNoHomonym()">
      </lib-client-card>
      <div *ngIf="!loadedClient" class="notice-to-start">
        <img class="no-display-low-res" src="assets/lib-common/images/long-left-arrow.svg">
        <p [innerHtml]="'SEARCH_RESULT.NOTICE_TO_START' | translate"></p>
      </div>
    </div>
  </div>
  <div class="client-homonyms-container">
    <h2>{{ 'SEARCH_RESULT.HOMONYMS_RESULTS' | translate }} - <span class="gold">{{ checkInItems.length }}</span></h2>


    <div class="homonyms-list">

      <div class="homonyms-list-top-header">
        <div class="vertical-line">
          <div class="topstar" *ngIf="selectedIndex==undefined">
            <img src="assets/images/motif.png">
          </div>
        </div>
      </div>

      <div *ngIf="showHomonymsLoader" class="homonyms-loader-content">
        <mat-spinner></mat-spinner>
      </div>

      <div *ngIf="!showHomonymsLoader" class="homonyms-list-content">

        <ng-template #homonymsWithSameBirthdate
                     [ngIf]="checkInItemsWithSameBirthdate && checkInItemsWithSameBirthdate.length > 0">
          <div class="homonyms-list-header">
            <h4>{{ 'SEARCH_RESULT.IDENTICAL_BIRTH_DATE' | translate }}</h4>
          </div>
          <!-- <div class="top-shadow-gradient"></div>  -->
          <cdk-virtual-scroll-viewport [itemSize]="50" class="rows-homonyme"
                                       [ngClass]="{
            'h-full' : checkInItemsWithDifferentBirthdate.length == 0,
            'disable-item-wrapper': isSaleOngoing
          }">
            <lib-check-in-item [id]="item.ciamId"
                               *cdkVirtualFor="let item of checkInItemsWithSameBirthdate;let i = index;templateCacheSize: 0; let lastItem = last; let firstItem = first"
                               class="pr-5"
                               [item]="item"
                               [index]="i"
                               [selectedIndex]="selectedIndexSameBirthdate"
                               [ngClass]="{'last-item-padding' : lastItem, 'first-item-padding' : firstItem, 'pointer-event-none': isSaleOngoing }"
                               (click)="selectClient(i, item, 'same')">
            </lib-check-in-item>
          </cdk-virtual-scroll-viewport>
          <!-- <div class="bottom-shadow-gradient"></div> -->
        </ng-template>

        <ng-template #homonymsWithDifferentBirthdate
                     [ngIf]="checkInItemsWithDifferentBirthdate && checkInItemsWithDifferentBirthdate.length > 0">
          <div class="homonyms-list-header pt-20 pb-5">
            <h4 class="mt-20" *ngIf="checkInItemsWithSameBirthdate.length > 0">Autres résultats</h4>
            <div class="sort-container" [ngClass]="{'ml-30' : checkInItemsWithSameBirthdate.length == 0}">
              <lib-sort *ngIf="showSortType" (sortType)="sortBy($event)"></lib-sort>
            </div>
          </div>
          <!-- <div class="top-shadow-gradient"></div> -->
          <cdk-virtual-scroll-viewport [itemSize]="50" class="rows-homonyme"
                                       [ngClass]="{'disable-item-wrapper': isSaleOngoing }">
            <lib-check-in-item [id]="item.ciamId"
                               *cdkVirtualFor="let item of checkInItemsWithDifferentBirthdate;let i = index;templateCacheSize: 0;  let lastItem = last; let firstItem = first"
                               class="pr-5"
                               [item]="item"
                               [index]="i"
                               [selectedIndex]="selectedIndexDifferentBirthdate"
                               [ngClass]="{'last-item-padding' : lastItem, 'first-item-padding' : firstItem,  'pointer-event-none': isSaleOngoing  }"
                               (click)="selectClient(i, item, 'different')">
            </lib-check-in-item>
          </cdk-virtual-scroll-viewport>
          <!-- <div class="bottom-shadow-gradient"></div> -->
        </ng-template>

      </div>

    </div>

  </div>
</div>
