import { firstValueFrom, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from './authentication.service';
import { HotelListContentManagerService } from './hotel-unifieddata-api/hotel-list-content-manager.service';
import { ISelectHotelListDialogData, SelectHotelListDialogComponent } from '../components/select-hotel-list-dialog/select-hotel-list-dialog.component';
import { IHotelList } from './hotel-unifieddata-api/dtos/hotel-list-dto';

const CURRENT_HOTEL = 'CURRENT_HOTEL';

@Injectable()
export class HotelListService {

  private currentHotelCode: string | null = null;
  private _currentHotel$: ReplaySubject<IHotelList | null> = new ReplaySubject<IHotelList | null>(1);

  get currentHotel$(): ReplaySubject<IHotelList | null> {
    return this._currentHotel$;
  }

  /**
   * Constructor
   */
  constructor(
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private hotelListContentManagerService: HotelListContentManagerService
  ) {
    this.loadOrSelectCurrentHotel().then();
    this.authenticationService.isLoggedIn.subscribe(async (isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        this.onSignOut();
      }
    });
  }

  /**
   * Called on sign out
   */
  private async onSignOut() {
    await firstValueFrom(this.setCurrentHotelCode(null));
  }

  /**
   * Call this function to set the current hotel
   * @param hotelCode
   */
  setCurrentHotelCode(hotelCode: string | null): Observable<IHotelList | null> {
    this.currentHotelCode = hotelCode;
    localStorage.setItem(CURRENT_HOTEL, hotelCode ?? '');    
    
    if (hotelCode) {
      return this.hotelListContentManagerService.getHotelByCode(hotelCode).pipe(
        tap((hotel: IHotelList | null) => this._currentHotel$.next(hotel))
      );
    } else {
      this._currentHotel$.next(null);
      return of(null);
    }
  }

  /**
   * Call this to select
   * @returns
   */
  private async loadOrSelectCurrentHotel(): Promise<IHotelList | null> {
    const storedHotelCode = localStorage.getItem(CURRENT_HOTEL);
    if (storedHotelCode && storedHotelCode !== '') {
      return await firstValueFrom(this.setCurrentHotelCode(storedHotelCode));
    } else {
      await this.openSelectHotelDialog();
    }
    return await firstValueFrom(this._currentHotel$);
  }

  /**
   * Opens the select hotel dialog
   * @returns
   */
  public async openSelectHotelDialog() {
    // Fetch the hotel list before opening the dialog
    const hotelList = await firstValueFrom(this.hotelListContentManagerService.getHotelList());

    let ref = this.dialog.open(SelectHotelListDialogComponent, {
      maxWidth: '700px',
      maxHeight: '500px',
      minWidth: '320px',
      minHeight: '450px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
      disableClose: true,
      data: {
        hotelList: hotelList,
        currentHotel: this.currentHotelCode ? await firstValueFrom(this.hotelListContentManagerService.getHotelByCode(this.currentHotelCode)) : null
      }
    });
    
    ref.afterClosed().pipe(
      switchMap((data: ISelectHotelListDialogData) => {
        return this.setCurrentHotelCode(data.currentHotel ? data.currentHotel.code : null);
      })
    ).subscribe();

  }

  getCurrentHotel(): Observable<IHotelList | null> {
    return this._currentHotel$.asObservable();
  }

  getHotelCodeSync(): string | null {
    return this.currentHotelCode;
  }

  getHotelListForUser(): Observable<IHotelList[]> {
    return this.hotelListContentManagerService.getHotelList();
  }
}
