import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AuthenticationService,
  CzamCommonModule,
  EnvironmentService,
  FirestoreService,
  SystemService, WebPushNotificationsService
} from "lib-common";
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpBackend } from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { InitService } from "../services/init.service";
import { VersionPageComponent } from './pages/version-page/version-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AccessDeniedPageComponent } from "./pages/access-denied-page/access-denied-page.component";
import { CasinoServiceModule } from "lib-common";
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';
import * as Sentry from "@sentry/angular";


export function createTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/lib-common/i18n/', suffix: '.json' },
  ])
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    VersionPageComponent,
    LoginPageComponent,
    AccessDeniedPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CzamCommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      // Register the ServiceWorker as soon as the app is stable
      // or after XX seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:2000'
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      }
    }),
    CasinoServiceModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (authenticationService: AuthenticationService,
        systemService: SystemService,
        firestoreService: FirestoreService,
        webpushService: WebPushNotificationsService,
        googleTagManagerConfiguration: GoogleTagManagerConfiguration,
        environmentService: EnvironmentService) =>
        () => InitService.init(authenticationService, systemService, firestoreService, webpushService, googleTagManagerConfiguration, environmentService),
      multi: true,
      deps: [AuthenticationService, SystemService, FirestoreService, WebPushNotificationsService, GoogleTagManagerConfiguration, EnvironmentService, Sentry.TraceService],
    },
    { provide: 'googleTagManagerMode', useValue: "noisy" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
