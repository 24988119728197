import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs';
import { InformDialogComponent } from '../components/inform-dialog/inform-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(private readonly updates: SwUpdate, public dialog: MatDialog) {
    console.log("App update service");
  }

  checkUpdate(origin: string) {
    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => {
          console.log("App update event " + origin + " : " + evt.type, evt);
          return evt.type === 'VERSION_READY';
        }))
      .subscribe(event => {
        this.showAppUpdateAlert(origin);
      });
  }

  showAppUpdateAlert(origin: string) {
    const title = 'Nouvelle version';
    const message = 'Une nouvelle version de l\'application <strong>' + origin + '</strong> est disponible. <br><strong>Cliquez sur le bouton "Installer"</strong><br> pour poursuivre votre navigation sur la dernière version.';
    const action = 'Installer';
    const icon = 'autorenew'

    let dialogRef = this.dialog.open(InformDialogComponent, {
      disableClose: true,
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel-inform-dialog',
      height: '350px',
      width: '400px',
      data: { message: message, title: title, action: action, icon: icon }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.doAppUpdate();
    });
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
