import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-inform-dialog',
  templateUrl: './inform-dialog.component.html',
  styleUrls: ['./inform-dialog.component.scss']
})
export class InformDialogComponent implements OnInit {

  public title: string = "";
  public message: string = "";
  public action: string = "";
  public icon: string = "";
  public link: string = "";

  constructor(
    private dialogRef: MatDialogRef<InformDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, action: string, title: string, icon: string; link: string }
  ) { }

  ngOnInit(): void {
    this.message = this.data.message ?? "";
    this.action = this.data.action ?? "Ok";
    this.title = this.data.title ?? "";
    this.icon = this.data.icon ?? "";
    this.link = this.data.link ?? null;
  }

  doAction() {
    if (!this.link) {
      this.dialogRef.close(true);
    }
    else {
      window.location.href = this.link
    }
  }
}
