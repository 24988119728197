import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {

  @Input() title: string = '';
  @Input() version: string | null = null;
  @Input() commitHash: string | null = null;
  @Input() packageGenerationDate: string | null = null;

}
