import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { catchError, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

/** Logout on 401 requests */
@Injectable()
export class LogoutInterceptorService implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private snackBar: MatSnackBar) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {



    return next.handle(req).pipe(catchError(async (err, caught) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          await this.authService.logout();

          this.snackBar.openFromComponent(
            SnackBarComponent, {
            data: { message: 'Déconnexion', icon: 'waving_hand', class: 'success' },
            duration: 3000,
            panelClass: 'dark-snackbar-success'
          });

        }
      }
      throw err;
    }));
  }
}
