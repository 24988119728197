import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { IEntryService } from "../../../../services/czam-api/entry.service";
import { ICustomerEntryDto } from "../../../../services/czam-api/dtos/customer-entry-dto";
import { IClientEntryDto } from "../../../../dtos/client-entry-dto";
import { ICustomerDeniedEntryDto } from "../../../../services/czam-api/dtos/customer-denied-entry-dto";

let today = new Date();
let birthDate = new Date();
birthDate.setFullYear(1980);

@Injectable({
  providedIn: 'root'
})
export class FakeEntryService implements IEntryService {

  constructor() { }

  saveCustomerEntry(customerEntry: ICustomerEntryDto): {} {
    console.log('saveCustomerEntry called');
    return of();
  }

  saveClientEntry(clientEntry: IClientEntryDto): {} {
    console.log('saveClientEntry called');
    return of();
  }

  saveDeniedEntry(deniedEntry: ICustomerDeniedEntryDto): {} {
    console.log('saveDeniedEntry called');
    return of();
  }

  counter(casinoCode: string): {} {
    return of();
  }
}
