export enum IdScanSubSteps {
  Prepare = 0,
  GrabYourPhone = 1,
  ChoseProcess = 2,
  IdExtraction = 3,
  IdManual = 4,
  ValidationProcess= 5,
  Done = 6
}

export enum BottomSheetCloseReason {
  Yes,
  No,
  Canceled
}

export enum SignatureSteps {
  Prepare = 0,
  GrabYourPhone = 1,
  Optins = 2,
  Signature = 3,
  Validation = 4,
  Done = 5
}
