import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from './authentication.service';

export interface GaItem {
  item_id: string;
  item_name: string;
}

export interface GtmTag {
  app_user_id: string;
  event: string;
  app_name: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

export class GtmService {
  public appName: string | null = null;

  constructor(private gtmService: GoogleTagManagerService, private authService: AuthenticationService) { }

  protected async createGtmTag(event: string, data?: { [key: string]: any }): Promise<GtmTag> {
    if (!this.appName) {
      throw new Error('App name is not set');
    }
    const user = await firstValueFrom(this.authService.user);

    // Push to data layer
    this.gtmService.getDataLayer().push({
      app_name: this.appName,
      app_user_id: user ? user.uid : 'unauthenticated',
    });


    return {
      app_user_id: user ? user.uid : 'unauthenticated',
      event: event,
      app_name: this.appName,
      ...data
    };
  }

  protected async sendTag(gtmTag: GtmTag) {
    this.gtmService.pushTag(gtmTag).catch(err => {
      console.error(err);
    });
  }

  public async sendPageViewTag(urlAfterRedirects: string) {
    const tag = await this.createGtmTag('page_view', { page_location: urlAfterRedirects });

    await this.sendTag(tag);
  }

  public async sendAppEvent(type: string) {
    const tag = await this.createGtmTag('app_event', { app_event_type: type });

    await this.sendTag(tag);
  }
}
